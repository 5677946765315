import React, { useEffect, useState } from 'react';
import konsole from './components/network/konsole';
import './App.css';
import Header from '../src/components/Headers/Header'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.min.js"
import Counter from './components/CounterChecking/Counter';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import ConfirmationList from './components/CounterChecking/ConfirmationList';
import FollowUpList from './components/CounterChecking/FollowUpList';
import Registration from './components/CounterChecking/Registration';
import CounterPlanner from './components/CounterPlanner/CounterPlanner';
import Congratulations from './components/Congratulations/Congratulations';
import VenuePayment from './components/VenuePayment/VenuePayment';
import AfterPaymentScreen from './components/AfterPaymentScreen'
import ConfirmBooking from './components/ConfirmBooking';
import LoginPage from './login';
import Dashboard from './components/Dashboard/Dashboard';
import commonLib from './components/control/commonLib';
import BulkRegistration from './components/Bulk/BulkRegistration';
import Classroomvenupayment from './components/ClassroomComponent/classroomPayment/Classroomvenupayment';
import Classroomregistration from './components/ClassroomComponent/Classroomregistration';
import Commonregcomponent from './components/ClassroomComponent/commonComponent/Commonregcomponent';
import Readmoreclassroom from './components/ClassroomComponent/Readmoreclassroom/Readmoreclassroom';
import ClassDashBord from './components/ClassroomComponent/classroomDashBord/ClassDashBord'
import PublishSeminar from './components/coordinatepublisher/publishseminar';
import MailToCoordinator from './components/CounterChecking/MailToCoordinator';
import PublishBothSeminar from './components/PublishBothSeminar';
import CustomeIdleTimer from './components/CustomeIdleTimer.js';
// import Mobileview from './components/Mobileview';

import PrivateRoute from './components/Routes/PrivateRoute'




export const UserContext = React.createContext({
  subtenantFileId: null,
  subtenantLogo: '',
  loader: false,
  loggedInUserRoles:[]
});



function App(props) {



  const [subtenantLogo, setSubtenantLogo] = useState('');
  const [ subtenantFileId, setSubtenantFileId ] =useState(null);
  const [loader, setLoader] = useState(false);
  const [loggedInUserRoles,setLoggedInUserRoles]=useState([])

  const subtenatLogoUrl = commonLib.getSessionStoarge('subtenatLogoUrl');
  const subtenantFileObj = commonLib.getSessionStoarge('subtenantFileId');




  useEffect(() => {
    if (subtenatLogoUrl !== null) {
      setSubtenantLogo(subtenatLogoUrl);
    }
  }, [subtenatLogoUrl])


  useEffect(() => {
    if (subtenantFileObj !== null) {
      setSubtenantFileId(subtenantFileId);
    }
  }, [subtenantFileId])


  


  return (
    <UserContext.Provider value={{ subtenantFileId: subtenantFileId, setSubtenantFileId: setSubtenantFileId, subtenantLogo: subtenantLogo, setSubtenantLogo: setSubtenantLogo, loader: loader, setLoader: setLoader,loggedInUserRoles,setLoggedInUserRoles }}>
      
      <div className="overlay position-relative">
        {loader &&
          <div className="d-flex align-items-center justify-content-center position-absolute overLayLoader">
            <div className="spinner-border" role="status" aria-hidden="true"></div>
          </div>
        }
        <CustomeIdleTimer/>
        <Router>


          <Switch>
            <Route  path="/" exact component={LoginPage} />
            {/* <Route path="/Dashboard" exact component={Dashboard} /> */}

            <PrivateRoute exact path='/Dashboard'>
              <Route exact component={Dashboard}/>
            </PrivateRoute>
            <PrivateRoute exact path='/PublishBoth'>
              <Route exact component={PublishBothSeminar}/>
            </PrivateRoute>

            <PrivateRoute exact path="/counter">
              <Route exact component={Counter}/>
            </PrivateRoute>

            <PrivateRoute exact path="/Registration">
            <Route component={Registration}/>
            </PrivateRoute>

            <PrivateRoute exact path="/CounterPlanner">
            <Route exact component={CounterPlanner}/>
            </PrivateRoute>

            <PrivateRoute exact path="/venuePayment">
              <Route component={VenuePayment}/>
            </PrivateRoute>

            <PrivateRoute exact path="/AfterPaymentScreen">
            <Route exact component={AfterPaymentScreen}/>
            </PrivateRoute>

            <PrivateRoute exact path="/confirmBooking" >
              <Route exact component={ConfirmBooking}/>
            </PrivateRoute>

            <PrivateRoute  exact path="/ConfirmationList">
              <Route exact component={ConfirmationList}/>
            </PrivateRoute>

            <PrivateRoute exact path="/Congratulations">
              <Route exact component={Congratulations}/>
            </PrivateRoute>

            
            <PrivateRoute exact path="/BulkRegistration">
              <Route exact  component={BulkRegistration }/>
            </PrivateRoute>
            
            <PrivateRoute exact path="/classroomvenupayment">
              <Route exact component={Classroomvenupayment }/>
            </PrivateRoute>

            <PrivateRoute path="/classroomregistration">
           <Route exact component={Classroomregistration }/>
            </PrivateRoute>
            
            <PrivateRoute exact path="/commonregcomponent">
              <Route exact component={Commonregcomponent}/>
            </PrivateRoute>
            
            <PrivateRoute exact path="/readmoreclassroom">
              <Route exact component={Readmoreclassroom}/>
            </PrivateRoute>
            
            <PrivateRoute exact path="/classDashBord">
            <Route exact component={ClassDashBord}/>
              </PrivateRoute>
            
            <PrivateRoute exact path="/Publishseminar">
            <Route exact component={PublishSeminar}/>
              </PrivateRoute>
            
            <PrivateRoute exact path="/MailToCoordinator">
            <Route exact component={MailToCoordinator}/>
            </PrivateRoute>
            <PrivateRoute exact path="/FollowUpList">
            <Route exact component={FollowUpList}/>
            </PrivateRoute>
           

           {/* <Route path="/FollowUpList" exact component={FollowUpList} />
            <Route path="/Registration" exact component={Registration} />
            <Route path="/CounterPlanner" exact component={CounterPlanner} />
            <Route path="/venuePayment" exact component={VenuePayment} />
            <Route path="/CounterPlanner/AfterPaymentScreen" exact component={AfterPaymentScreen} />
            <Route path="/CounterPlanner/confirmBooking" exact component={ConfirmBooking} />
            <Route path="/ConfirmationList" exact component={ConfirmationList} />
            <Route path="/Congratulations" exact component={Congratulations} />
            <Route path="/Counter" component={Counter } />
            <Route path="/BulkRegistration" component={BulkRegistration}/>
            <Route path="/classroomvenupayment" component={Classroomvenupayment}/>
            <Route path="/classroomregistration" component={Classroomregistration}/>
            <Route path="/commonregcomponent" component={Commonregcomponent}/>
            <Route path="/readmoreclassroom" component={Readmoreclassroom}/>
            <Route path="/classDashBord" component={ClassDashBord}/>
            <Route path="/Publishseminar" component={PublishSeminar}/>
            <Route path="/MailToCoordinator" component={MailToCoordinator}/>
 */}


          </Switch>
        </Router>
      </div>
    </UserContext.Provider>

  );
}

export default App;
