import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import commonLib from '../control/commonLib';
import konsole from '../network/konsole';
import LayoutComponent from '../LayoutComponent';
import './VenuePayment.css';
import Services from '../network/Services';
import Payment from './Payment';
import Notification from '../Notification';


export default function VenuePayment(props) {
  const GetResponse = commonLib.getObjFromStorage('GetResponse');
  const selectedUser  = (props.location.state !== undefined) ? props.location.state : GetResponse;
  konsole.log("num",selectedUser)
 
  const history = useHistory();
  const venueObj = commonLib.getObjFromStorage("venueObj");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const subtenantId = commonLib.getSessionStoarge('subtenantId');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('')
  const [orderId, setorderId] = useState('')
  const [showPayment, setshowPayment] = useState(false)
  const [showModal , setShowModal] = useState(false)
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  const [depositeClass, setdepositeClass] = useState(0)
  const [plannerPrize, setPlannerprize] = useState(null)
  const [discountedPrize, SetDiscountedprize] = useState(null)
  const [depositePrize, setDepositeprize] = useState(null)
  const [plannerIndex, setplannerindex] = useState(null)
  const isTrue = selectedUser.data1?.semUserStatusId == 3 || selectedUser.data1?.semUserStatusId == 4
  

    const handlePaymentMethod = (selectedPaymentMethod, method) => {
    //  if(isTrue == false && method == "Cash"){
     setSelectedPaymentMethod(selectedPaymentMethod);
     setPaymentMethod(method)
    //  }else if(isTrue == true){
    //   setSelectedPaymentMethod(selectedPaymentMethod);
    //  setPaymentMethod(method)
    //  }
   

  }

  // const handlePostPayment = () => {
  // if (selectedPaymentMethod == null) {
  // Toaster.warning("Please select payment method");
  // return;
  // }
  // history.push({
  // pathname: "/counterPlanner/AfterPaymentScreen",
  // state: selectedUser
  // });
  // }

  const [popsStatus, setPopsStatus] = useState()
  useEffect(()=>{
    if(popsStatus == false){
      setshowPayment(false)
    }else{
      setPopsStatus(true)

    }

  },[popsStatus])

  const handlepayment = () => {

    if (paymentMethod == '' || paymentMethod == null) {
      setWarning('Please select  payment method')
      setColor1("#ffcc00")
      setShowModal(true)
       return;
    } else {
      if(isTrue == true){
        setshowPayment(true)
        setPopsStatus(true)
       }else{
        // if(depositePrize == null || depositePrize == undefined || depositePrize == ""){
        //   setWarning('Please select Your deposit price')
        //   setColor1("#ffcc00")
        //   setShowModal(true)
        // }else{
          setshowPayment(true)
          setPopsStatus(true)
        // }
      }
     
     }
  }
  // const FinalPrice=(prize,discountedprize)=>{
  //     setPlannerprize(prize)
      
  //     SetDiscountedprize(discountedprize)
  //     if(prize==9950) SetBackgrounColr(1)
  //    if(prize==17950) SetBackgrounColr(2)
  
  //   }
   const depositePrice =(index,DepositePrice,prize,discountedprize)=>{
    setplannerindex(index)
    
    setPlannerprize(prize)
    SetDiscountedprize(discountedprize)
    setDepositeprize(DepositePrice)
    if(index == 1) setdepositeClass(1)
    if(index == 2) setdepositeClass(2)
   } 



  return (
    <LayoutComponent>
    <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>

      <div className=' container-fluid bgclr venuepayment-main confirmationBoooking-main-div'  >
        <div className='row'>
          <div className='col-sm-6  '>
            {subtenantId == 745 ? (<h4>WorkShop Registration: New Registration<b> </b></h4>) :
              ((isTrue == true)?
                <h4>Seminar Registration: New Registration<b> </b></h4>
                :
                <h4 >Appointment Booking :<b> Book an Appointment</b></h4>)

            }

            <p className='fs-5'>Topic : {seminarObj?.seminarTopic}</p>
          </div>
          {/* {(selectedUser.data1?.semUserStatusId === 3 && 4) ?  "" : 
        <div className="col-sm-6 text-end pe-3">
              <p className="mt-4 me-4">
                <span className="fw-bold fs-5" style={{ color: "#820024" }}>
                  Seleted Date {":"}
                </span>{" "}
                {selectedSlot?.date}
              </p>
              <p className="ms-2">
                <span className="fw-bold fs-5" style={{ color: "#820024" }}>
                  Seleted Time Slot {":"}
                </span>{" "}
                {selectedSlot?.time}{" "}
              </p>
            </div>} */}
        </div>
        <div className='container-fluid d-flex justify-content-center align-items-center ' >
          <div className='col-sm-12 bg-white d-flex flex-column flex-md-row p-0 p-md-5'  style={{Height:"100%"}}>
            <div className='col-xs-4 border-end'>
              <div className='ms-4 m-ms-md-0'>
                <dl className="user-Details-Div">
                  <h3><b>User Details</b></h3>
                  < div className="dt fs-5">First name : <span className="text-secondary">{ 
                  
                  isTrue == true ? selectedUser.userRegistration?.firstName                  :
                  selectedUser?.attendeeFullName}</span> </div>
                  <dd id="lblName"></dd>
                  <div className="dt fs-5">Email : <span className='text-secondary'>{
                  isTrue == true? selectedUser.userRegistration?.emailAddress                  :
                  selectedUser?.attendeeEmailAddress}</span></div>
                  <dd id="lblId"></dd>
                  <div className="dt fs-5">Cell No : <span className='text-secondary'>{
                  isTrue == true ?
                  
                  selectedUser?.userRegistration?.countryCode +" "+ (commonLib.formatPhoneNumber(selectedUser?.userRegistration?.phoneNumber?.slice(-10)))
                 
                  :
                  selectedUser?.attendeeMobileNo?.slice(0,-10) +" "+ (commonLib.formatPhoneNumber(selectedUser?.attendeeMobileNo?.slice(-10)))
                  } 
                  
                  </span></div>
                <dd id="lblId"></dd>
                  < div className="dt fs-5" >Price : <span className='text-secondary'>{isTrue == true ? `$${selectedUser.data2}`:(
                    <>

                   {/* <button className={`btn btn-primary me-3 ms-4 ${backgroundColor == 1? "Buttonfocus" : "newButton" } rounded-pill`} */}
                    {/* // onClick={()=>FinalPrice(9950,7450)} */}
                     {/* type="button"> */}
                      
                      <span className=''><del>$700</del> $350</span>
                      {/* </button> */}
                   {/* <button class={`btn btn-primary ${backgroundColor == 2 ? "Buttonfocus" : "newButton" } rounded-pill`}  */}
                  {/* //  onClick={()=>FinalPrice(17950,13450)} */}
                   {/* type="button"> */}
                   
                   {/* <span className='ms-1'>$13,450</span> */}
                   {/* </button> */}
                    </>
                      

                  )}</span></div>
                   {/* {isTrue == false ?
                    < div className="dt fs-5 mt-2" >Deposit: <span className='text-secondary'>
                    <>
                   <button className={`btn btn-primary me-3 ms-1 ${depositeClass == 1? "ButtonfocusDeposite" : "newButtonDeposite" } rounded-pill`} onClick={()=>depositePrice(1,500,9950,7450)} type="button">$500</button>
                   <button class={`btn btn-primary ms-2 ${depositeClass == 2 ? "ButtonfocusDeposite" : "newButtonDeposite" } rounded-pill`} onClick={()=>depositePrice(2,500,17950,13450)}type="button">$500</button>
                    </>
                    </span></div>: "" 
                   } */}
                 
                
                  <dd id="lblAmenities"></dd>
                </dl>
              </div>
            </div>
            <div className='col-12 col-md-6 ps-4' >
              <h4> Choose a Payment Method</h4>
              <div className='row'>
                <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(1, 'Card')}>
                  <div class="card">
                    <div class={`${(selectedPaymentMethod === 1) ? "seletectedIndexBackground" : ''} card-body text-center rounded `}>
                      <h5 class="card-title "><img src={`${(selectedPaymentMethod === 1) ? '/icons/creditActiveIcon.svg' : '/icons/creditcardPayment.svg'}`} /></h5>
                      <p className='fw-bold'> Credit/Debit Card</p>
                    </div>
                  </div>
                </div>
                {/* <div className={`col-10 col-md-4 p-2 ${isTrue == false ? "planner" : "cursor-pointer"}`} onClick={() => handlePaymentMethod(1, 'Card')}>
                  <div class="card">
                    <div class={`${(selectedPaymentMethod === 1) ? "seletectedIndexBackground" : ''} card-body text-center rounded `}>
                      <h5 class="card-title "><img src={`${(selectedPaymentMethod === 1) ? '/icons/creditActiveIcon.svg' : '/icons/creditcardPayment.svg'}`} /></h5>
                      <p className='fw-bold'> Credit/Debit Card</p>
                    </div>
                  </div>
                </div> */}
              
                <div className="col-10 col-md-4 p-2 cursor-pointer" onClick={() => handlePaymentMethod(3, "Cash")}>
                  <div class="card ">
                    <div class={`${(selectedPaymentMethod === 3) ? "seletectedIndexBackground" : ''} card-body text-center rounded `}>
                      <div class="">
                        <h5 className='card-title'><img src={`${(selectedPaymentMethod === 3) ? '/icons/cashActiveIcon.svg' : '/icons/cashPayment.svg'}`} /></h5>
                        <span className='fw-bold'>Cash</span>
                        <p className='vanu-counter'> </p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className='text-center col-6 m-auto mt-5 pb-4'>
                {/* <button type='text' className='px-4 py-1 theme-btn rounded text-center ' onClick={handlePostPayment}>Make Payment</button> */}
                <button type='text' className='px-4 py-1 theme-btn rounded text-center ' onClick={handlepayment}> Payment</button>

              </div>
            </div>
          </div>
        </div>
      </div >
      <>



        {
          showPayment ? <Payment plannerIndex={plannerIndex} selectedUser={selectedUser} plannerPrize={plannerPrize} discountedPrize={discountedPrize}   orderId={orderId} paymentMethod={paymentMethod} setPopsStatus={setPopsStatus}depositePrize={depositePrize} /> : ''
        }
        {/* <Modal show={showmodal1} >
        <Modal.Header  className="border-0" >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0" >Did you receive the cash ?</Modal.Body>
        <Modal.Footer className="border-0" >
        <Button  className='w-25 '  style={{ backgroundColor: '#d3d3d3' ,color:"#720C20"}}onClick={()=>onclickcheckifyesno('No')} >
           No
          </Button>
          <Button  className="w-25" style={{ backgroundColor: '#720C20' }} onClick={()=>onclickcheckifyesno('Yes')}  >
            Yes
          </Button>
          
          </Modal.Footer>
      </Modal> */}

      </>



    </LayoutComponent>

  );
};








