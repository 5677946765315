import Services from "../network/Services";
import config from '../../config.json'
export const mapApi = "https://maps.googleapis.com/maps/api/js";

export const mapKey = 'AIzaSyBaUn22pwovCzOxH7Uthivbd8_ScMkaEAI';


export const EnquiryRegistration = 1;
export const CounterWalkIn = 3;
export const CounterCheckIn = 4;
export const CounterPlanner = 7;
export const PAID = 8;
export const UNPAID = 9;
export const FollowUp = "FOLLOWUP";
export const ConstantEmail = "@aoe.com";
export const ConstantText = "1234567890";
export const ConstantText1 = "(123) 456-7890";
export const ConstantVenue = "Tucson, Arizona";
export const Constantlocate = "USA Location- to be announced soon";
export const ConstantZip = "";
// export const LeadStackerToken = "4e138444-4400-40f7-bb5c-2588d1b15910";//LPL
export const LeadStackerToken = "6606a0c3-2cbf-4a3a-b12d-484c15559f61";//LPL
export const LeadStackerToken1 = "6606a0c3-2cbf-4a3a-b12d-484c15559f61";//AO


export const intakeBaseUrl = (config?.DEMO) ? 'https://aointakeformuat.agingoptions.com/' : 'https://member.intake.agingoptions.com/';
export const AoAgenturl = (config.DEMO) ? 'https://aoagentuat.agingoptions.com/' : 'https://agent.agingoptions.com/';
export const AoAcaddemyUrl = (config.DEMO) ? "https://academy.nsplindia.co.in" : "https://agingoptionsacademy.com/"
export const EventBaseUrl =(config.DEMO)?"https://eventuat.agingoptions.com/":"https://events.agingoptions.com/";
export const CounterBaseUrl =  (config.DEMO)?"https://aoeventcounteruat.agingoptions.com/": "https://Event.counter.agingoptions.com/";
export const CounterMobileBaseUrl = (config.DEMO)?"https://aoeventcountermobileuat.agingoptions.com/": "https://event.mcounter.agingoptions.com/";
export const AoAdminBaseUrl = (config.DEMO)?'https://Aoadminuat.agingoptions.com/': 'https://AOAdmin.agingoptions.com/';;
export const AoPartnerBaseUrl =(config.DEMO)?"https://aopartneruat.agingoptions.com/": "https://partner.admin.agingoptions.com/";;
export const IntakeEntryBaseUrl =(config.DEMO)?"https://intakeentryformuat.azurewebsites.net/": "https://intakeentryform.azurewebsites.net/";;









///UserType
export const Registered = 'REGISTERED';
export const checkIn = 'CHECK_IN';
export const PlannerBooking = 'PLANNER_BOOKED';
export const plannerFeePaid = 'PLANNER_FEE_PAID';
export const plannerFeeUnpaid = 'PLANNER_FEE_UNPAID';
export const leadPostApi = (JsonObj) => {
    return new Promise((resolve, reject) => {
        Services.postContactLead(JsonObj).then((res) => {
            resolve(res);
        }).catch((err) => {
            resolve('err');
        });
    });

}
export const leadPostContactApi = (json, aWithDates) => {
    return new Promise((resolve, reject) => {
        Services.postContactLeadRegist(json, aWithDates).then((res) => {
            let responseId = res?.data?.contact?.id;


            let deleteTag = res?.data?.contact?.tags.filter(itemA => {
                let partsA = itemA.replace(/\s/g, '').toLowerCase().split(',');
                let partialMatch = aWithDates.some(itemB => {
                    let partsB = itemB.replace(/\s/g, '').toLowerCase().split(',');
                
                    // return partsA.slice(0, -1).join(',') == partsB.slice(0, -1).join(',')
                    return partsA.join(',') == partsB.join(',')
                   
                         });
                return partialMatch;
            });

            let json = {
                id: responseId,
                tags: aWithDates,
                deleteTagss: deleteTag,
                contact : "NEW"
            }
            resolve(json);
        }).catch((err) => {
            resolve('err');
        });
    });

}

export const leadCommonApi = async (result, method,tag) => {
    if (method == "POST" && result?.contact != "NEW" && result !== "err") {
       
        const results = await leadPostApi(result);
        return results
    } else if (method == "DELETE"  && result !== "err") {
        const results = await leadDeleteApi(result);
        return results
    }else if(method == "PUT"){
        const results = await leadPutApi(result,tag)
        return results
    }

}
export const leadDeleteApi = (JsonObj) => {
    return new Promise((resolve, reject) => {
        Services.deleteContactLead(JsonObj).then((res) => {
            resolve(res);
        }).catch((err) => {
            resolve('err');
        });
    });
}
export const leadPutApi = (JsonObj,tag) => {
    return new Promise((resolve, reject) => {
        Services.putContactLeadRegist(JsonObj,tag).then((res) => {
            resolve(res);
        }).catch((err) => {
            resolve('err');
        });
    });
}

export const GetResponse = (JsonObj, userData) => {
    return new Promise((resolve, reject) => {
        let currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        // let aWithDates = JsonObj?.method !== "PUT" && JsonObj?.tag.map(item => `${item},${formattedDate}`);
        let aWithDates = JsonObj?.method !== "PUT" && JsonObj?.tag.map(item => `${item}`);
      
        Services.getContactLead(JsonObj.email).then((res) => {
            let responseId = res?.data?.contacts[0]?.id;
            let deleteTag = JsonObj?.method !== "PUT" && res?.data?.contacts[0]?.tags.filter(itemA => {
                let partsA = itemA.replace(/\s/g, '').toLowerCase().split(',');
                let partialMatch = JsonObj?.method !== "PUT" && aWithDates.some(itemB => {
                    let partsB = itemB.replace(/\s/g, '').toLowerCase().split(',');
                    //  return partsA.slice(0, -1).join(',') == partsB.slice(0, -1).join(',');
                     return partsA.join(',') == partsB.join(',');
                 
                });
                return partialMatch;
            });

            let json = {
                id: responseId,
                tags: aWithDates,
                deleteTagss: deleteTag,
                isContact: true
            }
            resolve(json);
           
          
        }).catch((err) => {
            resolve(err?.response?.data?.email?.message)
        })
    })
}
export const leadGetApi = async (JsonObj, json) => {
    const results = await GetResponse(JsonObj);
    if (results == "The email address is invalid.") {
        let currentDate = new Date()
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Months are 0-based, so add 1
        const day = currentDate.getDate();
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        // let aWithDates = JsonObj.tag.map(item => `${item},${formattedDate}`);
        let aWithDates = JsonObj.tag.map(item => `${item}`);
        const data = await leadPostContactApi(json, aWithDates);
        return data
    } else {
        return results
    }

}
export const isNotValidNullUndefinedfile = (value) => {
    if (value !== undefined && value !== null && value !== '') {
      return true;
    }
    return false
  }
export function capitalizeName(name) {
    let words = name.split(' ');

    let capitalizedWords = words.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });
    let capitalizedName = capitalizedWords.join(' ');

    return capitalizedName;
}