import React, { useEffect, useContext } from "react";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import konsole from "./network/konsole";
import Services from "./network/Services";
import commonLib from "./control/commonLib";
import '../components/PublishBoth.css';
import { ModalBody } from "react-bootstrap";
import { UserContext } from "../App";



function PreviewMail(props) {
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const subtenatName = commonLib.getObjFromStorage("subtenatName");
  const stateObj = commonLib.getObjFromStorage("stateObj");
  const { setLoader } = useContext(UserContext);
  konsole.log("uniqueNum", props?.TextTemplateRes)

  konsole.log("uniqueNum", props)
  konsole.log("props?.previewTemplateData", props?.previewTemplateData)
  useEffect(() => {
    
      if(props?.commChannelId == 3 || props?.commChannelId == 1){
        if(props?.EmailTemplateRes?.templateContent !== undefined && props?.EmailTemplateRes?.templateContent !== null){
       document.getElementById('templateData').innerHTML = props?.EmailTemplateRes?.templateContent
       konsole.log("hgisrughir",props?.EmailTemplateRes?.templateContent)
      }}
      konsole.log("props?.TextTemplateRes?.textTemplateContent",props?.TextTemplateRes?.textTemplateContent)
    }, [props])
  
  const onCancel = () => {
    props?.cancelMOdal(false)
    props.setopenSendEmailModal(false)
  
    
  

  };


  const SendEmailData = (emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy) => {
    setLoader(true)
    // //alert("mail")
    const promises = Services.SendEmailCom(emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy);
    konsole.log("sample21222", emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy)
    promises.then(res => {
      setLoader(false)
      props?.cancelMOdal(false)
      konsole.log("createdBy11", res.data.data)





    })
      .catch(err => {
        setLoader(false)
        props?.cancelMOdal(false)
        konsole.log("err", err.response);

      }).finally(fin => {
        props?.cancelMOdal(false)
        konsole.log("finish");
         setLoader(false);
      })
  }
 
  const SendTextData =(textTemplateType, textTo, templateData, textTemplateId, add, mapindId,  textMappingTable, createdBy)=>{
   
    setLoader(true)
       let promise = Services.postSendText(textTemplateType, textTo, templateData, textTemplateId, 1, "tblSeminarAttendeeList", textMappingTable, createdBy)


    konsole.log("sendTextAPIFuncRes", textTemplateType, textTo, textTemplateId, 1, "tblSeminarAttendeeList", textMappingTable, createdBy)

    promise.then((response) => {
      setLoader(false)
      konsole.log("props?.textData?.textTemplateContent", response)
      props?.cancelMOdal(false)
    }).catch((error) => {
      setLoader(false)
      props?.cancelMOdal(false)
      konsole.log("sendTextAPIFuncError", error)
    }).finally(() => {
      setLoader(false)
      konsole.log("sendTextAPIFuncEnd")
    })
  
  }


   const replaceEmail =(templateResponse,uniquelinks)=>{
    let newTemplate = document?.getElementById('templateData')?.innerHTML
    konsole.log("propsss33333kdd",templateResponse?.templateContent,uniquelinks)

    let updatedTemplateData = newTemplate;
     
    if(props?.uniqueNumber === 1){
      updatedTemplateData = updatedTemplateData?.replace('@@SEMINARNAME', seminarObj?.seminarTopic)
      updatedTemplateData = updatedTemplateData?.replace(/@@UNIQUELINK/g, uniquelinks?.classroomLink)
      updatedTemplateData = updatedTemplateData?.replace(/@@SUBTENANTNAME/g, subtenatName)
    }
  else if(props?.uniqueNumber === 2){
      updatedTemplateData = updatedTemplateData?.replace('@@SEMINARNAME', seminarObj?.seminarTopic)
      updatedTemplateData = updatedTemplateData?.replace(/@@UNIQUELINK/g, uniquelinks?.plannerLink)
      updatedTemplateData = updatedTemplateData?.replace(/@@SUBTENANTNAME/g, subtenatName)

  }
  else if(props?.uniqueNumber === 3){
      updatedTemplateData = updatedTemplateData?.replace('@@SEMINARNAME', seminarObj?.seminarTopic)
      updatedTemplateData = updatedTemplateData?.replace(/@@PLANNERLINK/g, uniquelinks?.plannerLink)
      updatedTemplateData = updatedTemplateData?.replace(/@@WORKSHOPLINK/g, uniquelinks?.classroomLink)
      updatedTemplateData = updatedTemplateData?.replace(/@@PLANNERWORKSHOPLINK/g, uniquelinks?.planner_ClassroomLink)
      updatedTemplateData = updatedTemplateData?.replace(/@@SUBTENANTNAME/g, subtenatName)
    }
    konsole.log("classroomLinkclassroomLink",updatedTemplateData)
     return updatedTemplateData
    
  

    
   }
   const replaceText =(data1,data2)=>{
    konsole.log("propsss33333111",data1,data2)
    let newTemplate1 = document?.getElementById('templateData1')?.innerHTML
    // let updatedTemplateData = data1?.textTemplateContent;
    let updatedTemplateData = newTemplate1;

    if(props?.uniqueNumber === 1){
      updatedTemplateData = updatedTemplateData?.replace('@@SEMINARNAME', seminarObj?.seminarTopic)
      updatedTemplateData = updatedTemplateData?.replace(/@@UNIQUELINK/g, data2?.classroomLink)
      updatedTemplateData = updatedTemplateData?.replace(/@@SUBTENANTNAME/g, subtenatName)
    }
   else if(props?.uniqueNumber === 2){
      updatedTemplateData = updatedTemplateData?.replace('@@SEMINARNAME', seminarObj?.seminarTopic)
      updatedTemplateData = updatedTemplateData?.replace(/@@UNIQUELINK/g, data2?.plannerLink)
      updatedTemplateData = updatedTemplateData?.replace(/@@SUBTENANTNAME/g, subtenatName)
    }
   else if(props?.uniqueNumber === 3){
    updatedTemplateData = updatedTemplateData?.replace('@@SEMINARNAME', seminarObj?.seminarTopic)
    updatedTemplateData = updatedTemplateData?.replace(/@@PLANNERLINK/g, data2?.plannerLink)
    updatedTemplateData = updatedTemplateData?.replace(/@@WORKSHOPLINK/g, data2?.classroomLink)
    updatedTemplateData = updatedTemplateData?.replace(/@@PLANNERWORKSHOPLINK/g, data2?.planner_ClassroomLink)
    updatedTemplateData = updatedTemplateData?.replace(/@@SUBTENANTNAME/g, subtenatName)
    }
     return updatedTemplateData

   }

  const handelSubmit =()=>{
   
    konsole.log("propsss",props)
    let replaceTemplateEmail;
    let replaceTemplateText;
    let EmailTemplateDataRes = props?.EmailTemplateRes;
    let TextTemplateDataRes = props?.TextTemplateRes;
    let emailType = EmailTemplateDataRes?.templateType;
    let emailSubject = EmailTemplateDataRes?.emailSubject;
    let emailTemplateId = EmailTemplateDataRes?.templateId;
    let emailStatusId = 1;
    let emailMappingTable = "tblSeminarAttendeeList";
    let createdBy = stateObj?.userId;


       let user1 = props?.allUsers
        for(let i = 0;i<user1.length;i++){
     
          replaceTemplateEmail  = replaceEmail(props?.EmailTemplateRes,user1[i])
          replaceTemplateText = replaceText(props?.TextTemplateRes,user1[i])
          konsole.log("updatedTemplateData",replaceTemplateEmail)
           let emailTo = user1[i]?.emailAddress;
           let textNum = user1[i]?.mobileNo
            let emailContent = replaceTemplateEmail;
            let emailMappingTablePKId = user1[i]?.seminarAttendeeId;
            konsole.log("allUsers",props?.uniqueNumber)
            if(props?.uniqueNumber === 1){
            if(props?.commChannelId == 3){
              
            
             SendEmailData(emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy)
            SendTextData(TextTemplateDataRes?.textTemplateType, textNum, replaceTemplateText, TextTemplateDataRes?.textTemplateId, 1, "tblSeminarAttendeeList", emailMappingTablePKId, createdBy)
      
              
      
            }
            else if(props?.commChannelId == 2){
              //alert("1T")
              SendTextData(TextTemplateDataRes?.textTemplateType, textNum, replaceTemplateText, TextTemplateDataRes?.textTemplateId, 1, "tblSeminarAttendeeList", emailMappingTablePKId, createdBy)


              
      
              }
            
            else if(props?.commChannelId == 1){
              //alert("1E")
              SendEmailData(emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy)
                 }
          }
          if(props?.uniqueNumber === 2){
            if(props?.commChannelId == 3){
            SendEmailData(emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy)
            SendTextData(TextTemplateDataRes?.textTemplateType, textNum, replaceTemplateText, TextTemplateDataRes?.textTemplateId, 1, "tblSeminarAttendeeList", emailMappingTablePKId, createdBy)
      
              //alert("2et")
      
            }
            else if(props?.commChannelId == 2){
              //alert("2T")
              SendTextData(TextTemplateDataRes?.textTemplateType, textNum, replaceTemplateText, TextTemplateDataRes?.textTemplateId, 1, "tblSeminarAttendeeList", emailMappingTablePKId, createdBy)

            }
            else if(props?.commChannelId == 1){
              //alert("2E")
              SendEmailData(emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy)

            }
          }
          if(props?.uniqueNumber === 3){
            //alert("button3")
            if(props?.commChannelId == 3){
              SendEmailData(emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy)
              SendTextData(TextTemplateDataRes?.textTemplateType, textNum, replaceTemplateText, TextTemplateDataRes?.textTemplateId, 1, "tblSeminarAttendeeList", emailMappingTablePKId, createdBy)
        
              //alert("3et")
      
            }
            else if(props?.commChannelId == 2){
              //alert("3T")
              SendTextData(TextTemplateDataRes?.textTemplateType, textNum, replaceTemplateText, TextTemplateDataRes?.textTemplateId, 1, "tblSeminarAttendeeList", emailMappingTablePKId, createdBy)
        
            }
            else if(props?.commChannelId == 1){
              //alert("3E")
              SendEmailData(emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy)
        
            }
          }
        }
      }
   
    
  


  return (
    <div>

      <Modal show={props?.openSendEmailModal} className="SampleData1"
        onCancel={onCancel}
        borderRadius={20}
        closable={false}
        footer={false}

        maskClosable={false}
      >
        <Modal.Body
          style={{
            // maxHeight: 'calc(100vh - 105px)',
            // overflowY: 'auto',


          }}
        >

          <div
            style={{
              border: "1px solid #720c20",
              width: "auto",
              margin: "-14px",
              backgroundColor: "#720c20",
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              marginTop: '-16px'
            }}
            className="rounded-top"
          >
            <div className=""></div>
            <div className="fs-5 ">{props?.commChannelId === 3 ? "Send Text & Mail" : props?.commChannelId === 2 ? "Send Text" : "Send Mail"}</div>

            <div className="me-2 mt-1" style={{ cursor: "pointer" }} onClick={onCancel}>X</div>
          </div>

          <br />
          {/* <br /> */}

          <div >
            <ModalBody
              style={{
                maxHeight: 'calc(100vh - 222px)',
                overflowY: 'auto',
                marginRight: '-0.8rem',
                marginLeft: '-13px'


              }}
              className="vertical-scroll"
            >
              <div contentEditable = "true" id="templateData">

              </div>
              { props?.TextTemplateRes?.textTemplateContent !== null && props?.TextTemplateRes?.textTemplateContent !== undefined ? (

                <div contentEditable = "true" id="templateData1">
                 {props?.TextTemplateRes?.textTemplateContent}
                </div>
              ) : ""}

            </ModalBody>
          </div>

          <Row className="d-flex flex-column">
            <div className="me-4">
              <Button className="w-100 mt-2"
                style={{
                  backgroundColor: "#720c20",
                  color: "white",
                  border: "none",
                  width: "150%",
                  borderRadius: "5px",
                  marginBottom: "10px"
                }}
                
                onClick={handelSubmit}
              >
                {props?.commChannelId === 3 ? "Send Text & Mail" : props?.commChannelId === 2 ? "Send Text" : "Send Mail"}

              </Button>
            </div>
            <div className="md-2">
              <Button className="w-100"
                style={{
                  backgroundColor: "white",
                  color: "#720C20",
                  border: "2px solid #720c20",
                  width: "150%",
                  borderRadius: "5px",
                  marginBottom: "10px"

                }}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>

          </Row>
        </Modal.Body>
      </Modal>

    </div>
  )

}

export default PreviewMail