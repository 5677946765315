import React, {useState}from 'react';
import { read, utils, writeFile } from 'xlsx';
import '../components/Convertexcle.css'


const ConvertToExcel = (props) => {
    const {data,headings,pName} = props;
    
    const [heading,setHeadings] = useState([headings])
    
    // konsole.log("data",data)
    
    

    const handleExport = (heading,movies,name) => {
      
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, heading);
        utils.sheet_add_json(ws, movies, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, name);
        
    }
    
  return (
    <>
     <div className="col-sm-2 d-flex justify-content-center mb-auto mt-auto">
    <button className=' reg d-flex justify-content-center align-items-center rounded  text-center mobile'style={{ border: '2px solid #720c20',background:"white",color:"#720c20",height:"46px" }} type='button' id='btnRegister'
                onClick={()=>handleExport(heading,data,pName)}
              >
                Export to Excel
              </button>
    </div>
    </>
    
  )
}

export default ConvertToExcel