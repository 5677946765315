import React, { useState, useEffect } from 'react'
// import konsole from '../../components/network/konsole'
import commonLib from '../control/commonLib'
import { AoPartnerBaseUrl } from '../network/url'
import konsole from '../network/konsole'


const PublishSeminar = () => {

  const [roleid, setroleid] = useState()
  const stateObj = commonLib.getObjFromStorage('stateObj');
  konsole.log("stateObj", stateObj)
  // const GetResponse = JSON.parse(sessionStorage.getItem('GetResponse'));
  const userId = stateObj?.userId
  konsole.log("aab",userId)
  let params = `appState=${stateObj.appState}&userId=${userId}&loggedUserId=${userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}`;
  window.location.replace(`${AoPartnerBaseUrl}?token=` + window.btoa(params));
  konsole.log("params",stateObj.appState,stateObj, params)
 


}


export default PublishSeminar