import React  ,{useEffect,useState} from 'react'

const Notification = (props) => {
const [data, setdata] = useState(0)
const [style, setstyle] = useState()
const [timer, setTimer] = useState(0);
const [isOn, setIsOn] = useState(false);


// konsole.log("props.showModal",props.showModal)

useEffect(() => {
    setTimeout(() => {
       props.setShowModal(false)
       }, 5000)
    })

  return (
    <>
    {(props.showModal == true) &&
          <div className="alert alert-info position-fixed end-0 top-0 mt-2 me-4 right-0 text-white border-0" style={{ width: '90%', maxWidth: '300px',backgroundColor : props.color, zIndex:"10000",transitionDuration: "500ms",
          }}>
            <div>
           {props.message}
         </div>
        {/* <div className='progress'>
            <div className='progress-done bg-primary'style={style}></div>

        </div> */}
          </div>
          }
    </>
  )
}

export default Notification