
import { useContext, useState } from "react";
import { useEffect } from "react";
import '../Headers/header.css';
import { useHistory } from "react-router-dom";
import { checkIn } from "../control/Constant";
import LayoutComponent from "../LayoutComponent";
import konsole from "../network/konsole";
import Services from "../network/Services";
import commonLib from "../control/commonLib";
import { UserContext } from "../../App";
import { CounterCheckIn, Registered, ConstantEmail, ConstantText } from "../control/Constant";
import ConvertToExcel from "../ConvertToExcel";
import Notification from "../Notification";
import SearchBar from "../SearchBar";
import PreviewMail from "../PreviewMail";
import Modal from 'react-bootstrap/Modal';



const CounterPlanner = (props) => {


  const history = useHistory();
  const [exclee, setExclee] = useState([])
  const Location = commonLib.getObjFromStorage("Location");
  const { setLoader } = useContext(UserContext);
  const [counterPlannerList, setCounterPlannerList] = useState([]);
  const [query, setquery] = useState("");
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const venueObj = commonLib.getObjFromStorage("venueObj");
  const subtenantId = commonLib.getSessionStoarge('subtenantId');
  const [UserNo, setUserNo] = useState()
  const [referenceMode, setReferenceMode] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showModa12, setShowModal2] = useState(false)
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  const [openSendEmailModal, setopenSendEmailModal] = useState(false)
  const [TextTemplateRes, setTextTemplateRes] = useState(null)
  const [EmailTemplateRes, setEmailTemplateRes] = useState(null)
  const [uniqueNumber, setuniqueNumber] = useState(null)
  const [commChannelId, setcommChannelId] = useState(null)
  const [allUsers, setUserUsers] = useState(null)
  const [slots, setSlots] = useState(null)

  const [showModal1, setShowModal1] = useState(false)
  const [showMoals, setshowMoals] = useState(false)
  const headings = ["S. No", "First name", "Last name", "Email", "Phone", "Attendees", "Lead From", "Check-In Status"]

  useEffect(() => {
    sloats()
    getCounterPlannerList(checkIn);
    let promise = Services.getReferenceMode();
    promise.then(res => {
      konsole.log("reference mode", res);
      setReferenceMode(res.data.data)
      counterCheckInList(res.data.data);

    })
  }, []);



  const handelClose = (data) => {
    konsole.log("datatta", data)
    setopenSendEmailModal(data)


  }




  const counterCheckInList = (leadFormData) => {
    setLoader(true);
    konsole.log("seminarUserData", checkIn, subtenantId, seminarObj.seminarId)

    const promise = Services.getSeminarUserList(checkIn, seminarObj.subtenantId, seminarObj.seminarId);

    promise.then((res) => {
      setLoader(false);
      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      setUserNo(res.data.data[0]?.noOfSeatsBooked)

      const Array = counterResFilter[0]?.attendees.map((res, index) => {
        return {
          S_No: index + 1,
          firstName: res.fName,
          lastName: res.lName,
          EmailId: res.emailId,
          Mobile_Number: commonLib.formatPhoneNumber(res?.mobileno?.slice(-10)),
          Attendees: res.userGuestsNos,
          Lead_From: callReferenceValueApi(leadFormData, res?.leadFrom)[0]?.label,
          Check_In: res.semUserStatusId == 4 || res.semUserStatusId == 5 ? 'Yes' : 'No'
        }
      })
      
      setExclee(Array)

      setCounterPlannerList(counterResFilter[0])

    }).catch((err) => {
      konsole.log(`error `, err.response)
    }).finally(error => {
      konsole.log("finish");
      setLoader(false);
    })
  }
  const closeButton1 = () => {
    setshowMoals(false)
  }
  const userValidationByRoleId = (datas) => {
    if (slots?.length > 0) {
  
      const promises = Services.GetUserRolebByID(datas?.attendeeUserId);
      promises.then(res => {
       
        let len = res.data.data.length;
        let data = res.data.data;
        let newData = []

        for (let i = 0; i < len; i++) {

          newData.push(data[i].roleId)
        }



        konsole.log('registered Checkin res11', data[0]?.subtenantId);
        if (data[0]?.subtenantId === seminarObj?.subtenantId) {
          if (newData.every((x) => { return x !== 1 && x !== 9 && x !== 10 })) {
            if (newData.every((x) => x === 2) && (newData.length > 1)) {
              setWarning("This user is already a staff member, please let them know to contact the front office.")
              setColor1("#ffcc00")
              setShowModal(true)

            }
            else {
              sentData(counterPlannerList?.seminarId, datas?.seminarAttendeeId, datas?.semUserStatusId, "PLANNER")

            }

          }
          else {
            sentData(counterPlannerList?.seminarId, datas?.seminarAttendeeId, datas?.semUserStatusId, "PLANNER")

          }
        }
        else {
          setWarning("This user is already registered with the other firm.")
          setColor1("#ffcc00")
          setShowModal(true)

        }

      })
        .catch(err => {

        }).finally(fin => {

        })
    } else {
      setWarning("Please create slots as no slots available!")
      setColor1("#ffcc00")
      setShowModal(true)

    }

  }



  const getUserRoles = (userId, selectedUserObj) => {
    const plannersState = true
    const ClassroomStatus = false
    commonLib.setDatatoSession('calssroom', JSON.stringify(ClassroomStatus))
    setLoader(true);
    const seminarid = counterPlannerList.seminarId
    const selectedUser = {
      attendeeFullName: selectedUserObj.fName + " " + selectedUserObj.lName,
      seminarAttendeeUserId: selectedUserObj.attendeeUserId,
      attendeeEmailAddress: selectedUserObj.emailId,
      attendeeMobileNo: selectedUserObj.mobileno,
      seminarAttendeeId: selectedUserObj.seminarAttendeeId,
      seminarid: seminarid,
      plannersState: plannersState
    }
    commonLib.setSessionStoarge("attendeeObj", JSON.stringify(selectedUser));
 
    setLoader(false);


    const promises = Services.GetUserRolebByID(userId);
    promises.then(res => {
      konsole.log("newData111", res.data.data)
      let len = res.data.data.length;
      let data = res.data.data;
      let newData = []

      for (let i = 0; i < len; i++) {

        newData.push(data[i].roleId)
      }

      if (data[0]?.subtenantId === seminarObj?.subtenantId) {
        if (newData.every((x) => { return x !== 1 && x !== 9 && x !== 10 })) {
          if (newData.every((x) => x === 2) && (newData.length > 1)) {
            setWarning("This user is already a staff member, please let them know to contact the front office.")
            setColor1("#ffcc00")
            setShowModal(true)

          }
          else {

            history.push({
              // pathname: '/venuePayment',
              pathname: '/AfterPaymentScreen',
              state: selectedUser
            })
          }

        }
        else {
          history.push({
            // pathname: '/venuePayment',
            pathname: '/AfterPaymentScreen',
            state: selectedUser
          })
        }
      }
      else {
        setWarning("This user is already registered with the other firm.")
        setColor1("#ffcc00")
        setShowModal(true)
      }

      const found = newData.find(element => element);




    })
      .catch(err => {
      }).finally(fin => {
      })

  }

  const handleAttendeeSelect = (index) => {
    const selectedUserObj = counterPlannerList.attendees[index];
    const lastEightDigitArray = array => array.slice(-8);
    const lastTenDigitArray = array => array.slice(-10);

    if (lastEightDigitArray(selectedUserObj?.emailId) !== ConstantEmail && lastTenDigitArray(selectedUserObj?.mobileno) !== ConstantText) {
      getUserRoles(selectedUserObj?.attendeeUserId, selectedUserObj)
    }
    else {
      setShowModal(true)
      let text1 = lastEightDigitArray(selectedUserObj?.emailId) == ConstantEmail && lastTenDigitArray(selectedUserObj?.mobileno) == ConstantText ? "Please edit the user's temporary email & contact number to a valid email & contact number before booking a Appointment." :
        (lastEightDigitArray(selectedUserObj?.emailId) == ConstantEmail) ? "Please edit the user's temporary email to a valid email before booking a Appointment." :
          "Please edit the user's temporary contact number to a valid contact no before booking a Appointment."
      setWarning(text1)
      setColor1("#ffcc00")
    }



  }

  const callReferenceValueApi1 = (IdData) => {

    konsole.log("IdDataIdDataIdData", referenceMode)
    return referenceMode.filter((x) => x.value == IdData)
  }
  const GetEmailTemplateUsers = (TempalteId, isActive) => {
    setLoader(true)
    const promises = Services.GetEmailTemplateUser(TempalteId, isActive);
    promises.then(res => {
      setLoader(false)

      let data2 = res.data.data[0]

      setEmailTemplateRes(data2)

      setopenSendEmailModal(true)


    })

      .catch(err => {
        konsole.log("err", err.response);
        setLoader(false)
        setWarning("Please set your template in CMS.")
        setColor1("#ffcc00")
        setShowModal1(true)

      }).finally(fin => {
        setLoader(false);
      })

  }
  const GetTextTemplateUsers1 = (TempalteId, isActive, unique) => {
    const promises = Services.getTextTemplateFunc(TempalteId, isActive);
    promises.then(res => {
      let data2 = res.data.data[0]
      setTextTemplateRes(data2)
      setopenSendEmailModal(true)


    })

      .catch(err => {
        konsole.log("err", err.response);
        setWarning("Please set your template in CMS.")
        setColor1("#ffcc00")
        setShowModal1(true)

      }).finally(fin => {
        setLoader(false);
      })

  }

  const occurrenceData = (occurrenceId, isActive, subtenantId, uniqueNumber) => {
    setLoader(true
    )
    const promise = Services.postgetCommMedium(occurrenceId, isActive, subtenantId);
    promise.then((res) => {
      setLoader(false)
      let textId = res.data.data[0]?.applicableTextTempId;
      let commChannelId = res?.data?.data[0]?.commChannelId
      setcommChannelId(commChannelId)
      let applicableEmailTempId = res.data.data[0]?.applicableEmailTempId
      if (commChannelId == 1) {
        GetEmailTemplateUsers(applicableEmailTempId, true, uniqueNumber)

      }
      else if (commChannelId == 2) {
        GetTextTemplateUsers1(textId, true, uniqueNumber)
      }
      else if (commChannelId == 3) {
        GetTextTemplateUsers1(textId, true, uniqueNumber)
        GetEmailTemplateUsers(applicableEmailTempId, true, uniqueNumber)

      }






    }).catch((err) => { 
      setLoader(false)
      setWarning("Please set your template in CMS.")
      setColor1("#ffcc00")
      setShowModal1(true)
      setLoader(false)
    }).finally(error => {
      konsole.log("finish");
      setLoader(false);
    })



  }

  const handelSendData = (seminarId, LinkType, uniqueNumber) => {
    if (slots?.length > 0 && LinkType == "PLANNER") {
      setuniqueNumber(uniqueNumber)
      setLoader(true)
      const promise = Services.getPlannerClassRoomLinks(seminarId, LinkType);
      konsole.log("rest11433", seminarId, LinkType, uniqueNumber)
      promise.then((res) => {
        setLoader(false)
        let user = res?.data?.data
        setUserUsers(user)
        let occurrenceId = uniqueNumber === 1 ? 8 : uniqueNumber === 2 ? 3 : 17;
        occurrenceData(occurrenceId, true, subtenantId, uniqueNumber)

      }).catch((err) => {
        konsole.log(`errorw11 `, err?.response?.data?.errorFlag)
        setWarning(err?.response?.data?.errorFlag)
        setColor1("#ffcc00")
        setShowModal(true)
        setLoader(false)
      }).finally(error => {
      })

    } else {
      setWarning("Please create slots as no slots available!")
      setColor1("#ffcc00")
      setShowModal(true)
    }
  }
  const getCounterPlannerList = (checkIn) => {
    setLoader(true);
    const promise = Services.getSeminarUserList(checkIn, subtenantId, seminarObj.seminarId);
    promise.then((res) => {
      setLoader(false);
      let counterResFilter = res.data.data.filter((res) => {
        return (res.seminarId == seminarObj.seminarId)
      })
      setCounterPlannerList(counterResFilter[0])
    }).catch((err) => {
      konsole.log(`error %% ${err}`)
    }).finally(fin => {
      konsole.log("finish");
      setLoader(false);
    })
  }


  const callReferenceValueApi = (leadFormData, IdData) => {
    return (leadFormData !== undefined && leadFormData !== null && leadFormData !== "") ? leadFormData.filter((x) => x?.value == IdData) : "noData"
  }
  const sloats = () => {
    setLoader(true);
    let promises = Services.getPlannerCalender(true, 1, "UPCOMING", seminarObj.subtenantId);
    promises
      .then((res) => {
        setLoader(false);

        konsole.log("res", res)
        let slots1 = res?.data?.data
        setSlots(slots1)
         })
      .catch((error) => {
        konsole.log("error1", error)
        setLoader(false);
      })
      .finally(error => {
        konsole.log("finish");

      })

  }
  const ShowSlots = () => {
    if (slots !== undefined && slots !== null && slots !== "" && slots.length > 0) {


      setshowMoals(true)

    }
    else {

      setWarning("There are no Upcoming Slots")
      setColor1('#ffcc00')
      setShowModal(true)


    }






  }
  const dayFormate = (date) => {

    const d = new Date(date);
    let day = d.getDay()

    switch (day) {
      case 0:
        return day = "Sun";
        break;
      case 1:
        return day = "Mon";
        break;
      case 2:
        return day = "Tue";
        break;
      case 3:
        return day = "Wed";
        break;
      case 4:
        return day = "Thur";
        break;
      case 5:
        return day = "Fri";
        break;
      case 6:
        return day = "Sat";
    }

  }
  const filterData = () => {
     let arr = []
    let mainData =
    {
      plannerUserId: [],
      plannerDate: [],
      plannerTime: [],
      plannerFullName: "",
    }
   return (

      <div className="row ms-2 ms-sm-0 gap-3 align-items-center justify-content-center w-100">

        {
         slots !== null && slots !== undefined && slots !== "" && slots.map((item, index) => {
            const { formattedDate, dayAndYear } = commonLib?.getFormattedDateNew(item.plannerDate);
            return (
              <>

                <div key={index}className="gray-border rounded border border-dark p-2 vertical-scroll" style={{ maxWidth: '220px' }}>
                  {formattedDate}<br />{dayAndYear}<br /> {item.plannerTime}
                </div>
              </>
            )
          })
        }
      </div>

    )



  }

  const sentData = (seminarId, seminarAttendeeId, semUserStatusId, LinkType) => {
    let promise = Services.getSelfCheckingLinks(seminarId, seminarAttendeeId, semUserStatusId, LinkType)
    promise.then((response) => {
      window.location.replace(response?.data?.data?.uniqueURL)

      setLoader(false);

    }).catch((error) => {
      konsole.log("getaddressbyuseridError", error)
    }).finally(() => {

    })
  }





  return (
    <LayoutComponent>
      <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal} />
      <div className="container-fluid bgclr counter-main-div">
        <div className="pt-1 d-flex justify-content-between w-100 mobileLocate">
          <h5>
            <b>Appointment Booking - Counter</b>
          </h5>
          <div className='p-0'>
            <h6>{Location?.seminarType !== "Direct" ? "" : "Venue : " + Location?.seminarVenue}</h6>
            {
              venueObj?.venueAddressLine1 !== null && venueObj?.venueAddressLine1 !== undefined && venueObj?.venueAddressLine1 !== "" &&
              <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >
                {Location?.seminarType !== "Direct" ? "" : "Location : " + venueObj?.venueAddressLine1}
       
              </p>
            }
            <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
              {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}</p>
          </div>
        </div>

        <div className="Main-container-counter bg-white">


          <div className="row px-3 ">
            <h6 className="counter-text mt-2 mobileText1">{seminarObj?.seminarTopic} (Check Ins : {UserNo}/{counterPlannerList?.seatingCapacity})</h6>

            <div className="col-sm-12  col-md-5 col-lg-5 me-auto   counter-text mt-auto mb-auto">
              <SearchBar setquery={setquery} />

            </div>

            <div className="col-sm-2 d-flex justify-content-center mb-auto mt-auto">
              <button className='reg d-flex justify-content-center align-items-center rounded text-white text-center mobile' type='button' id='btnRegister'
                onClick={ShowSlots}
              >
                Available Slots
              </button>
            </div>
            <div className="col-sm-3 d-flex justify-content-center mb-auto mt-auto mobileDiv" style={{ width: "20%" }} >
              <button style={{ width: "100%" }} className='reg d-flex justify-content-center align-items-center rounded text-white text-center mobile' type='button' id='btnRegister'
                onClick={() => handelSendData(seminarObj?.seminarId, "PLANNER", 2)}
              >
                Send Appointment Booking Link
              </button>
            </div>



            {exclee.length > 0 ? <ConvertToExcel data={exclee} headings={headings} pName="Appointment Booking.xlsx" /> : ""}
          </div>
         
          <div className="table-contaier mt-1">
            <table className="table bg-white  gap-3 ;">

              <thead style={{ position: "sticky", top: -1, zIndex: '5' }}>
                <tr className="bgclr gap-3 text-danger fs-6">
                  {headings.map((ele) => {
                    return (
                      <th className={ele == "S. No" || ele == "First name" || ele == "Last name" || ele == "Email" || ele == "Phone" ? "" : "text-center"}>{ele}</th>


                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {
                  counterPlannerList.attendees !== undefined && counterPlannerList?.attendees.length > 0 && counterPlannerList?.attendees?.filter(item => item.fName?.toLowerCase().includes(query) ||
                    item.lName?.toLowerCase()?.includes(query) ||
                    item.emailId?.toLowerCase()?.includes(query) ||
                    item.mobileno?.toLowerCase()?.includes(query)).map((item, index) => {

                      return (
                        <tr key={index} className="cursor-pointer" >
                          <td className="">{index + 1}</td>
                          <td className="">{item?.fName?.charAt(0)?.toUpperCase() + item?.fName?.slice(1)}<br /><a onClick={() => userValidationByRoleId(item)} className="mt-2">Book Appointment</a></td>
                          <td className="" onClick={() => handleAttendeeSelect(index)}>{item?.lName?.charAt(0)?.toUpperCase() + item?.lName?.slice(1)}</td>
                          <td className="" onClick={() => handleAttendeeSelect(index)}>{item.emailId.split(',')[0]}</td>
                          <td className="" onClick={() => handleAttendeeSelect(index)}>
                            {item.mobileno.slice(0, -10)}
                            {" "}
                            {commonLib.formatPhoneNumber(item.mobileno.slice(-10))}
                          </td>
                          <td className="text-center" onClick={() => handleAttendeeSelect(index)}>{item.userGuestsNos}</td>
                          <td className="text-center" onClick={() => handleAttendeeSelect(index)}>{callReferenceValueApi1(item.leadFrom)[0]?.label}</td>
                          <td className="text-center" onClick={() => handleAttendeeSelect(index)}>{(item.semUserStatusId == 4 || item.semUserStatusId == 5) ? 'Yes' : 'No'}</td><br />

                        </tr>

                      )
                    })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {
        openSendEmailModal == true ? <PreviewMail setopenSendEmailModal={setopenSendEmailModal} cancelMOdal={handelClose} openSendEmailModal={openSendEmailModal}
          titleText="Send Email" TextTemplateRes={TextTemplateRes} allUsers={allUsers} uniqueNumber={uniqueNumber} EmailTemplateRes={EmailTemplateRes} commChannelId={commChannelId} /> : ""}
      <Modal show={showMoals} onHide={closeButton1} size="xl" overflowY={"scroll"} backdrop={"static"}>
        <Modal.Header closeButton style={{ border: "none" }}>

        </Modal.Header>
        <Modal.Body className="border-0 p-0" style={{ height: "100%", maxHeight: "80vh", overflowY: "scroll" }}>
          <div className='contenArea container-fluid   bgclr' >
            <div className='row'>
              <div className='col-sm-6 '>
                <p className='fs-4'><b>Topic</b>: {seminarObj?.seminarTopic}</p>
              </div>
            </div>
            <div className='container after-payment-container bg-white text-center vertical-scroll ' style={{ maxHeight: '100%', overflowY: 'auto' }}>
              <h1 className='congratulationNew fw-bold fs-1'>Available Slots</h1><br />

              {filterData()}
              <div className="mt-4"></div>
             </div>
          </div>

        </Modal.Body>



      </Modal>





    </LayoutComponent>
  );
};

export default CounterPlanner;
