import React from 'react';
import { Col, Row } from 'react-bootstrap';
import '../components/SearchBar.css'

function SearchBar(props) {
  return (
    <div>
      <Row>
      <Col xs={12} lg={10}> 
      <div className="input-group newS mb-auto mt-auto">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  style={{height:'46px'}}
                  onChange={(e) => props.setquery((e.target.value)?.toLowerCase())}
                />
                <span className="input-group-text" id="basic-addon1">
                  <img src="./images/vector.png" />
                </span>
              </div>
      </Col>
      </Row>
        
    </div>
  )
}

export default SearchBar