import React, { useEffect,useState, useContext } from "react";
import LayoutComponent from './LayoutComponent'
import commonLib from './control/commonLib';
import '../components/PublishBoth.css';
import Services from './network/Services';
import PreviewMail from './PreviewMail';
// import { CounterCheckIn, Registered } from "../control/Constant";
import { Registered } from "./control/Constant";
import konsole from "./network/konsole";
import config from '../../src/config.json'
import { UserContext } from "../App";
import Notification from "./Notification";

function PublishBothSeminar() {
    const seminarObj = commonLib.getObjFromStorage("seminarObj");
    const venueObj = commonLib.getObjFromStorage("venueObj");
    const seminarObj1 = commonLib.getObjFromStorage("seminarList2");
    const subtenantId = commonLib.getObjFromStorage("subtenantId");
    const subtenatName = commonLib.getObjFromStorage("subtenatName");
    const Location = commonLib.getObjFromStorage("Location");
    const stateObj = commonLib.getObjFromStorage("stateObj");
    const [openSendEmailModal, setopenSendEmailModal] = useState()
    const [SeminarList, setSeminarList] = useState(null)
    
    const [UserNo, setUserNo] = useState(0)
    const [allUsers, setUserUsers] = useState(null)
    const [tempData, setTempData] = useState(null)
    const { setLoader } = useContext(UserContext);
    const [slots, setslots] = useState(null)

    const [showModal1 , setShowModal1] = useState(false)
    const [warning, setWarning] = useState()
    const [Color1, setColor1] = useState("")
    const [TextTemplateRes, setTextTemplateRes] = useState(null)
    const [EmailTemplateRes, setEmailTemplateRes] = useState(null)
    const [uniqueNumber, setuniqueNumber] = useState(null)
    const [commChannelId, setcommChannelId] = useState(null)
    
    
    useEffect(() => {
      counterCheckInList()
      getSeminarPublish(745, true, "UPCOMING");
      ShowSlots()
     }, [])


    const handelClose =(data)=>{
  //  konsole.log("datatta",data)
   setopenSendEmailModal(data)
  setTextTemplateRes(undefined)
  setEmailTemplateRes(undefined)
   
  
   
    }
    const ShowSlots =()=>{
       
      setLoader(true);
      konsole.log("rerhsgdusyg",true,1,"UPCOMING",seminarObj.subtenantId)
      let promises = Services.getPlannerCalender(true,1,"UPCOMING",seminarObj.subtenantId);
      promises
        .then((res) => {
          setLoader(false);
          setslots(res?.data?.data)

           })
        .catch((error) => {
          konsole.log("error1", error)
          setLoader(false);
        })
        .finally(error => {
          konsole.log("finish");
          
        })
       
  
     
     
    }
   
    
       
   

    
    const getSeminarPublish = (subtenantId1, isActive, seminarType) => {

      const promise = Services.getClassroomPublisherDetail(subtenantId1, isActive, seminarType);
      //konsole.log("ssd", subtenantId1, true, "UPCOMING")
      setLoader(true);
      promise.then(response => {
          setLoader(false)
          // setSeminarList(response.data.data);
         
      }).catch((e) => {
          setSeminarList(e.response?.data?.message);
          
          
          
          // alert(e.response.data.message)
      }).finally(() => {
          // setLoader(false);
      });
  }
    const counterCheckInList = () => {
        // setLoader(true);
        const promise = Services.getSeminarUserList(Registered, seminarObj.subtenantId, seminarObj.seminarId);
        // konsole.log("rest11", Registered, seminarObj.subtenantId, seminarObj.seminarId)
        promise.then((res) => {
        //   setLoader(false);
          konsole.log("konsoleGetApiData", res)
        //   konsole.log("konsoleGetApiData1", counterData)
        
    
    
          let counterResFilter = res.data.data.filter((res) => {
            return (res.seminarId == seminarObj.seminarId)
          })
          
            // setCounterData(counterResFilter[0]?.attendees)
            konsole.log("counterResFilter",counterResFilter[0].attendees)

            // let newData = counterResFilter[0].attendees.filter((ele)=>{
            //     return  
            // })
         
          
          konsole.log("semniarFilter11", counterResFilter[0]?.attendees);
          let sum = 0;
          for (let i = 0; i < counterResFilter[0]?.attendees.length; i++) {
    
            let num = parseInt(counterResFilter[0]?.attendees[i].userGuestsNos);
            sum += num
    
    
          }
          // konsole.log("sum", sum)
          setUserNo(sum)
    
          }).catch((err) => {
          konsole.log(`errorw `, err.response)
        }).finally(error => {
          konsole.log("finish");
          setLoader(false);
        })
      }
      
    const GetEmailTemplateUsers = (TempalteId,isActive)=>{
        setLoader(true)
        const promises = Services.GetEmailTemplateUser(TempalteId,isActive);
          promises.then(res => {
            setLoader(false)
          
          let data2 = res.data.data[0]
         
          setEmailTemplateRes(data2)
       
              setopenSendEmailModal(true)
         
          
        })
      
        .catch(err => {
          konsole.log("err", err.response);
          setLoader(false)
          setWarning("Please set your template in CMS.")
          setColor1("#ffcc00")
          setShowModal1(true)
          
        }).finally(fin => {
          // konsole.log("finish");
          setLoader(false);
        })
    
      }
    const GetTextTemplateUsers1 = (TempalteId,isActive,unique)=>{
      //  //alert("hhh")
        const promises = Services.getTextTemplateFunc(TempalteId,isActive);
    
        konsole.log("restR11111", TempalteId,isActive);
        promises.then(res => {
            konsole.log("ihkjgiu",TempalteId)
          let data = res.data.data[0]?.textTemplateContent
          let data2 = res.data.data[0]
          setTextTemplateRes(data2)
          konsole.log("restapi1112",data2)
          
          
          setopenSendEmailModal(true)
        
          
        })
      
        .catch(err => {
          konsole.log("err", err.response);
          setWarning("Please set your template in CMS.")
          setColor1("#ffcc00")
          setShowModal1(true)
          
        }).finally(fin => {
          // konsole.log("finish");
          setLoader(false);
        })
    
      }
    
    const occurrenceData =(occurrenceId,isActive,subtenantId,uniqueNumber)=>{
          setLoader(true
        )
        const promise = Services.postgetCommMedium(occurrenceId, isActive, subtenantId);
        konsole.log("rest11", occurrenceId, isActive, subtenantId,uniqueNumber)
        promise.then((res) => {
            setLoader(false)
            let textId = res.data.data[0]?.applicableTextTempId;
             let commChannelId = res?.data?.data[0]?.commChannelId
            setcommChannelId(commChannelId)
            let applicableEmailTempId = res.data.data[0]?.applicableEmailTempId
            if(commChannelId == 1){
              //alert("OnlyEmail")
              GetEmailTemplateUsers(applicableEmailTempId,true,uniqueNumber)
              
            }
          else if(commChannelId == 2){
            //alert("Only Text")
            GetTextTemplateUsers1(textId,true,uniqueNumber)
        }
        else if(commChannelId == 3){
          //alert("Both")
         GetTextTemplateUsers1(textId,true,uniqueNumber)
         GetEmailTemplateUsers(applicableEmailTempId,true,uniqueNumber)

        }
        
          
       
         
      
      
      }).catch((err) => {
       
      konsole.log(`errorw `, err.response.data)

       if(err.response.status == 404){
       
        setWarning("Please set your template in CMS.")
        setColor1("#ffcc00")
        setShowModal1(true)
        setLoader(false)

       }
   
      // if(err.response.status == 404){
        
      //   let subtenantId1 = config.DEFAULT_SUBTENANTID_FOR_OCCURRENCE
        
      //   handelSend(occurrenceId, isActive, subtenantId1,unique) 
      
        
      // if(err.response.status == 404){
      //   let subtenantId1 = config.DEFAULT_SUBTENANTID_FOR_OCCURRENCE
      //   handelSend(occurrenceId, isActive, subtenantId1,unique) 
      

    // }
    }).finally(error => {
      konsole.log("finish");
      setLoader(false);
    })



    }
   
    const handelSendData =(seminarId,LinkType,uniqueNumber)=>{
      setuniqueNumber(uniqueNumber)
      
      if(slots?.length > 0 && LinkType == "PLANNER"){
        afterValidation(seminarId,LinkType,uniqueNumber) 
      }else if(SeminarList != "No Data Found" && LinkType == "CLASSROOM"){
        afterValidation(seminarId,LinkType,uniqueNumber)  
      }else if(LinkType == "PLANNER_CLASSROOM" && slots?.length > 0 && SeminarList != "No Data Found"){
        afterValidation(seminarId,LinkType,uniqueNumber) 
     }else{
      
        if((slots?.length < 1 ) && (LinkType == "PLANNER_CLASSROOM") && (SeminarList == "No Data Found")){
          setWarning("Please create slots and workshop as no slots and workshop available!")
          setColor1("#ffcc00")
          setShowModal1(true)
        }
       else if((slots?.length < 1) && (LinkType == "PLANNER" || LinkType == "PLANNER_CLASSROOM")){
          setWarning("Please create slots as no slots available!")
          setColor1("#ffcc00")
          setShowModal1(true)
        }else if((SeminarList == "No Data Found") && (LinkType == "CLASSROOM" || LinkType == "PLANNER_CLASSROOM")){
          setWarning("Please create workshop as no workshop available!")
          setColor1("#ffcc00")
          setShowModal1(true)
        }
      
      }
      
     


     
    }
    const afterValidation =(seminarId,LinkType,uniqueNumber)=>{
      
      setLoader(true)
      const promise = Services.getPlannerClassRoomLinks(seminarId,LinkType);
      konsole.log("rest11433", seminarId,LinkType,uniqueNumber)
      promise.then((res) => {
          setLoader(false)
        let user = res?.data?.data
        // setnewUser(user)
        setUserUsers(user)
        let occurrenceId = uniqueNumber === 1 ? 8 : uniqueNumber === 2 ? 3 : 17;
        occurrenceData(occurrenceId,true,subtenantId,uniqueNumber)

      }).catch((err) => {
        konsole.log(`errorw11 `, err.response.data)
        
          setWarning(err?.response?.data?.errorFlag)
          setColor1("#ffcc00")
          setShowModal1(true)
          // //alert("There are no checked-in users available at this moment. Please check-in the user to send the link.")
         
        setLoader(false)
      }).finally(error => {
        konsole.log("finish");
      //   setLoader(false);
      })}
return (
    <LayoutComponent>
      <Notification showModal={showModal1} message={warning} color={Color1} setShowModal={setShowModal1}/>
        <div className="container-fluid bgclr publish-main-div">
        <div className="pt-4 d-flex justify-content-between w-100 mobileLocate ">
          <h4>
            <b>Check - In Entry - Counter</b>
            
          </h4>
          <div className='p-0'>
            {
              seminarObj?.seminarTypeId !== 47 && 
              <> 
               
                <h5>{Location?.seminarType !== "Direct" ?  "" : "Venue : " + Location?.seminarVenue}</h5>
                {
                  venueObj?.venueAddressLine1 !== null && venueObj?.venueAddressLine1 !== undefined && venueObj?.venueAddressLine1 !== ""&&
                <p className="bi bi-geo-alt form-label fs-6" style={{ color: "#720c20" }} >
                  {Location?.seminarType !== "Direct" ?  "" : "Location : " + venueObj?.venueAddressLine1 }
                 
                  </p>
                }
                </>
             }
            <p className=" bi bi-geo-alt form-label fs-6" style={{ color: "#720C20" }} >
              {commonLib.getFormattedDate(seminarObj?.seminarDate)} & {commonLib.getFormattedTime(seminarObj?.seminarTime)}</p>
          </div>
        </div>

        <div className="Main-container-counter bg-white" style={{paddingBottom:"10%"}}>
          <div className="row px-3 pb-3">
            <h6 className="counter-text mt-3 mobileText1">{seminarObj?.seminarTopic} (Attendees  : {UserNo}/{seminarObj1?.noOfSeats})</h6>
             
             <div className='d-flex flex-column name'>
             <button className='btn  text-white  p-2 mt-2 float-end rounded new' style={{ backgroundColor: '#720c20' }} type='button' id='btnRegister'onClick={() =>handelSendData(seminarObj?.seminarId,"CLASSROOM",1)}  >Publish Workshop Link</button>
             <button className='btn  text-white  p-2 mt-2 float-end rounded new' style={{ backgroundColor: '#720c20' }} type='button' id='btnRegister' onClick={() =>handelSendData(seminarObj?.seminarId,"PLANNER",2)} >Publish Appointment Booking Link</button>
             <button className='btn  text-white   p-2 mt-2 float-end rounded  new ' style={{ backgroundColor: '#720c20'}} type='button' id='btnRegister' onClick={() =>handelSendData(seminarObj?.seminarId,"PLANNER_CLASSROOM",3)}  >Publish Both Links</button>

             </div>
            
           
          
          </div>
          </div>
      </div>
      {
        openSendEmailModal == true ? <PreviewMail setopenSendEmailModal={setopenSendEmailModal} cancelMOdal={handelClose} openSendEmailModal={openSendEmailModal}
         titleText="Send Email" TextTemplateRes={TextTemplateRes}allUsers={allUsers}uniqueNumber={uniqueNumber}EmailTemplateRes={EmailTemplateRes}commChannelId={commChannelId} />:"" }
   
    </LayoutComponent>
  )
}

export default PublishBothSeminar