
import React, { useState, useRef, useEffect,useContext } from 'react';
import commonLib from '../control/commonLib';
import Services from '../network/Services';
import { useHistory } from 'react-router-dom';
import config from "../../../src/config.json"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import { UserContext } from '../../App';
import occurrenceId from '../control/occurrence';
import konsole from '../network/konsole';
import { COUNTERBASEURL , COUNTERBASEURL1 ,COUNTERBASEURL2,PAYMENTURL} from '../network/url';

const Payment = (props) => {
  const { setSubtenantLogo, setLoader, setSubtenantFileId } = useContext(UserContext);
  const clickbuttonref = useRef()
  let appstate = commonLib.getObjFromStorage('stateObj')?.appState
  const history = useHistory();
  const [orderId, setorderId] = useState('')
  const [showmodal1, setshowmodal1] = useState(false)
  const stateObj = commonLib.getObjFromStorage("seminarObj");
  const seminarList2 = commonLib.getObjFromStorage("seminarList2");
  const stateObj1 = commonLib.getObjFromStorage("stateObj");
  const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
  const subtenatName = commonLib.getObjFromStorage("subtenatName");
  const attendeeObj = commonLib.getObjFromStorage("attendeeObj");
  const classroomObj1 = commonLib.getObjFromStorage("calssroom");
  let date1 = new Date()
  const seminarData = props?.selectedUser?.data1
  
  const isTrue = seminarData?.semUserStatusId == 3 || seminarData?.semUserStatusId == 4
  let dateTime = moment(date1).format('MMMM Do YYYY h:mm A')
  useEffect(() => {
    commonLib.setDatatoSession("countRenderData", JSON.stringify(true))
    if (props?.paymentMethod === "Cash") {
      setshowmodal1(true)
    }
    else {
      adduserApiCall()
    }


  }, [])

  useEffect(() => {
    // konsole.log("orderId",orderId)

    if (orderId !== '') {
      clickbuttonref.current.submit()
    }
  }, [orderId])
  const SendEmailData = (emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy) => {
    const promises = Services.SendEmailCom(emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy);
    konsole.log("sample21", emailType, emailTo, emailSubject, emailTemplateId, emailContent, emailStatusId, emailMappingTable, emailMappingTablePKId, createdBy)
    promises.then(res => {
      konsole.log("createdBy11", res.data.data)




    })
      .catch(err => {
        konsole.log("err", err.response);

      }).finally(fin => {
        konsole.log("finish");
        // setLoader(false);
      })
  }

  // const classRoomRegistration =()=>{
  //   let postData = Services.postClassRoomRegister()
  //   postadduser.then((res) => {


  //     konsole.log("responseresponse", res.data.data)

  //   }).catch((err) => {
  //     // konsole.log("err", err)
  //   })
  // }
  const numberFormate = (number) => {
  const lastTenDigitArray = array => array.slice(-10);
  return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
    }

  const GetEmailTemplateUsers = (TempalteId, isActive, response, newUser) => {
    let subtenantId = sessionStorage.getItem("subtenantId")

    const promises = Services.GetEmailTemplateUser(TempalteId, isActive);
    const cash = "Cash"
    // konsole.log("restR11111", userDetails);
    promises.then(res => {
      let data = res.data.data[0]?.templateContent
      let data2 = res.data.data[0]
      konsole.log("restapi1", data2)

      let allData = {
        ATTENDEENAME: isTrue == true ? props?.selectedUser?.userRegistration?.firstName : (props?.selectedUser1) ? props?.selectedUser1?.selectedUser?.attendeeFullName : props?.selectedUser.attendeeFullName,
        EVENTNAME: isTrue == true ? stateObj?.seminarTopic + " " + "Seminar" : (props?.selectedUser1) ? props?.selectedUser1?.selectedUserObj?.seminarTopic + " " + "Workshop" :
        //  props?.plannerIndex == 1 ? "Legal LifePlanning" : "Custom LifePlanning",
        "LifePlanner",
        PAYMENTMODE: "Cash",

        PAIDAMOUNT: isTrue == true ? "$" + props?.selectedUser?.data2 : (props?.selectedUser1) ? "$" + props?.selectedUser1?.selectedUserObj?.seminarFee : "$" +
        //  props?.depositePrize,
        350,

        PAYMENTDATE: dateTime,
        COORDINATORNAME: userLoggedInDetail?.memberName,
        COORDINATOREMAIL: userLoggedInDetail?.primaryEmailId,
        COORDINATORCELLNO: numberFormate(userLoggedInDetail?.cellNo),

        ATTENDEEEMAIL: isTrue == true ? props?.selectedUser?.userRegistration?.emailAddress : (props?.selectedUser1) ? props?.selectedUser1?.selectedUser?.attendeeEmailAddress
            : props?.selectedUser.attendeeEmailAddress,

        ATTENDEECELLNO: isTrue == true ? numberFormate(props?.selectedUser?.userRegistration?.phoneNumber) : (props?.selectedUser1) ? numberFormate(props?.selectedUser1?.selectedUser?.attendeeMobileNo)
            : numberFormate(props?.selectedUser.attendeeMobileNo),

        BOOKTYPE: isTrue == true ? "registration to" : (props?.selectedUser1) ? "registration to" : "booking a",

        SUBTENANTNAME: subtenatName,

        emailId: isTrue == true ? props?.selectedUser?.userRegistration?.emailAddress : (props?.selectedUser1) ? props?.selectedUser1?.selectedUser?.attendeeEmailAddress
            : props?.selectedUser.attendeeEmailAddress,

        seminarAttendeeId: attendeeObj?.seminarAttendeeId.toString(),
        // Description: isTrue == true ? seminarList2?.description : (props?.selectedUser1) ? props?.selectedUser1?.selectedUserObj?.description
        //     : `This ${props?.plannerIndex == 1 ? "Legal LifePlanning" : "Custom LifePlanning"} is available at a discounted price of $${props?.discountedPrize} (after applying a 25% discount on the standard price). You can lock this discounted price by paying a non refundable deposit of $${props?.depositePrize} now.`,










      }


      
      occurrenceId.callOccurrenceIdFunc(13, subtenantId, allData)

    })

      .catch(err => {
        konsole.log("err", err.response);

      }).finally(fin => {
        // konsole.log("finish");
        // setLoader(false);
      })

  }
  const cashPayment = (data) => {

    let adduserobjCash = {
      "userId": data?.userId,
      "memberId": data?.order.memberId,
      "orderId": data?.order?.orderId,
      "transactionId": "NA",
      "paymentType": 2,
      "updatedBy": stateObj1?.userId,
    }
    let classOrderId = data?.order?.orderId
    commonLib.setDatatoSession('classOrderId', JSON.stringify(classOrderId))


    let cashUser = Services.postuserorderAddUserOrderCash(adduserobjCash)
    cashUser.then((response) => {
      GetEmailTemplateUsers(14, true)
      konsole.log("dwt", response.data.data?.order?.paymentType)
      const data = { paymentType: response.data.data?.order?.paymentType, orderId: response.data.data.order.orderId }

      if ( isTrue == true || props?.selectedUser1) {
        if (classroomObj1 === true) {
          history.push({
            pathname: '/confirmBooking',
            state: data?.paymentType
          })

        }
        else {
          history.push({
            pathname: '/Congratulations',
            state: data?.paymentType
          })
        }

      }

      else {
        history.push({
          pathname: '/confirmBooking',
          state: data

        })
      }
      konsole.log("waste", response.data.data)
    }).catch((error) => {
      konsole.log("errt", error)

    })
  }
  const adduserApiCall = () => {
    let adduserobj = {
      "userId": isTrue == true ? seminarData?.userId : (props?.selectedUser1) ? props?.selectedUser1?.selectedUser?.seminarAttendeeUserId
        : props?.selectedUser.seminarAttendeeUserId
      ,
      "shippingCost": 0,
      "taxCost": 0,
      "totalCost": isTrue == true ? props?.selectedUser.data2 : (props?.selectedUser1) ? props?.selectedUser1?.selectedUserObj?.seminarFee :
      //  props?.depositePrize,
      350,
      "createdBy": stateObj1?.userId,
      "productList": [
        {
          "productType": isTrue == true ? "SEMINAR" : (props?.selectedUser1) ? "CLASSROOM" : "PLANNER",
          "productId": isTrue == true ? seminarData?.seminarId : (props?.selectedUser1)
              ? props?.selectedUser1?.selectedUserObj?.seminarId : props?.selectedUser?.seminarid,                                              
          // "productName": isTrue == true ? stateObj.seminarTopic : (props?.selectedUser1) ? props?.selectedUser1?.selectedUserObj?.seminarTopic : stateObj.seminarTopic ,
          "productName": isTrue == true ? stateObj.seminarTopic : (props?.selectedUser1) ? props?.selectedUser1?.selectedUserObj?.seminarTopic : "Initial Consultation",
         
          "quantity": isTrue == true ? props?.selectedUser.userRegistration?.noOfAttendee : (props?.selectedUser1)? 1 : 1,
          "productPrice": isTrue == true ? props?.selectedUser?.data2 : (props?.selectedUser1) ? props?.selectedUser1?.selectedUserObj?.seminarFee :
          //  props?.depositePrize
          700
                }
      ]
    }

    setLoader(true)
    let postadduser = Services.postuserorderAddUserOrder(adduserobj)
    postadduser.then((res) => {
      setLoader(false)
      if (props?.paymentMethod === "Cash") {
        cashPayment(res.data.data)
      }
      else {
        setorderId(res?.data?.data?.order?.orderId)
      }

    }).catch((err) => {
    })
  }


  const onclickcheckifyesno = (data) => {
    konsole.log("sem1", data)
    if (data === "Yes") {
      adduserApiCall()
    }
    else {
      props.setPopsStatus(false)
      setshowmodal1(false)
    }

  }
  return (

    <>
      {/* <form action="https://aopaymentdev.azurewebsites.net/Catalog/PaymentRequest" */}
      {/* <form action="https://aopaymentdev.agingoptions.com/Catalog/PaymentRequest" */}

      <form
        action={`${PAYMENTURL}/Catalog/PaymentRequest`}

        method="post" ref={clickbuttonref} >

        <div>
          <input type="hidden" name="AppState" id="AppState" value={appstate} />
          <input type="hidden" name="ReturnUrl" id="ReturnUrl"

            value={
              isTrue == true ?
                ` ${COUNTERBASEURL}` : (props?.selectedUser1) ? `${COUNTERBASEURL2}` : ` ${COUNTERBASEURL1}`
            } />

          <input type="hidden" name="UserId" value={
            isTrue == true ? seminarData?.userId : (props?.selectedUser1) ? props?.selectedUser1?.selectedUser?.seminarAttendeeUserId : props?.selectedUser?.seminarAttendeeUserId} />
          <input type="hidden" name="OrderId" value={orderId} />
          <input type="hidden" name="PaymentType" value={props?.paymentMethod} />
        </div>
        <div>

        </div>
      </form>
      <Modal show={showmodal1} >
        <Modal.Header className="border-0" >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-0" >Did you receive the cash ?</Modal.Body>
        <Modal.Footer className="border-0" >
          <Button className='w-25 ' style={{ backgroundColor: '#d3d3d3', color: "#720C20" }} onClick={() => onclickcheckifyesno('No')} >
            No
          </Button>
          <Button className="w-25" style={{ backgroundColor: '#720C20' }} onClick={() => onclickcheckifyesno('Yes')}  >
            Yes
          </Button>

        </Modal.Footer>
      </Modal>

    </>
  )
}
export default Payment