import React, { useState, useRef } from 'react';
import { read, utils, writeFile } from 'xlsx';
import konsole from '../network/konsole';
import Notification from '../Notification';
import './fileUploder.css'

const FileUpload = (props) => {
    const [data, setData] = useState([]);
    const wrapperRef = useRef(null);

    const [fileName, setFileName] = useState("")
    const [newFile, setNewFile] = useState()
    const [showModal , setShowModal] = useState(false)
    const [warning, setWarning] = useState()
    const [Color1, setColor1] = useState("")

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const readUploadFile = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

                    const dataParse = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 })
                    setData(rows)
                    konsole.log("dataParse", dataParse)
                    konsole.log("dataParse1", rows)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const fileRemove = (file) => {
        const updatedList = [...props.fileList];
        updatedList.splice(props.fileList.indexOf(file), 1);
        props.setFileList(updatedList);

    }

    const lowerCaseKeys = (jsonObject) => {

       
   let nam= jsonObject.map((ele)=>{
    const lowerKeys = {}; 
        Object.keys(ele).forEach((key) => {
           return  lowerKeys[key.toLowerCase()] = ele[key]; 
            });
            
            //konsole.log("samname",lowerKeys);
       })
       
        
        
        
      };
    const onFileDrop = ($event) => {
        const newFile = $event.target.files[0];
 
        if (newFile.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && newFile.type !== "text/plain" && newFile.type !== "text/csv") {
            // alert(`${newFile.name} is a not Excel and csv  File`)
            setWarning(`${newFile.name} is a not Excel and csv  File`)
            setColor1("#ffcc00")
            setShowModal(true)
        }
        else {

            if (newFile) {
                const updatedList = [...props.fileList, newFile];
               

            }
            const files = $event.target.files;
            if (files.length) {
                const file = files[0];
                const reader = new FileReader();
                reader.onload = (event) => {
                    const wb = read(event.target.result);
                    const sheets = wb.SheetNames;
              //konsole.log("name",(wb.Sheets[sheets[0]]))
               const a  = (wb.Sheets[sheets[0]])

              
              const array =[
              (wb.Sheets[sheets[0]]).A1 ? ((wb.Sheets[sheets[0]]).A1.v)  :  "",
              (wb.Sheets[sheets[0]]).B1 ? (wb.Sheets[sheets[0]]).B1.v : "",
              (wb.Sheets[sheets[0]]).C1 ? (wb.Sheets[sheets[0]]).C1.v: "",
              (wb.Sheets[sheets[0]]).D1 ? (wb.Sheets[sheets[0]]).D1.v : "",
              (wb.Sheets[sheets[0]]).E1 ? (wb.Sheets[sheets[0]]).E1.v : "",
              (wb.Sheets[sheets[0]]).F1 ? (wb.Sheets[sheets[0]]).F1.v : "",
              (wb.Sheets[sheets[0]]).G1 ? (wb.Sheets[sheets[0]]).G1.v : "",
              (wb.Sheets[sheets[0]]).H1 ? (wb.Sheets[sheets[0]]).H1.v : "",
              (wb.Sheets[sheets[0]]).I1 ? (wb.Sheets[sheets[0]]).I1.v: "",
              (wb.Sheets[sheets[0]]).J1 ? (wb.Sheets[sheets[0]]).J1.v: "",
              (wb.Sheets[sheets[0]]).K1 ? (wb.Sheets[sheets[0]]).K1.v: "",
              (wb.Sheets[sheets[0]]).L1 ? (wb.Sheets[sheets[0]]).L1.v : ""
                ]
              
   
                 
                    if (sheets?.length) {
                        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        // lowerCaseKeys(rows)
                        let sample = []
                        rows.map((ele)=>{
                            let  lowerKeys = {}; 
                                Object.keys(ele).forEach((key) => {
                                   return  lowerKeys[key.toLowerCase()] = ele[key]; 
                                    });
                                    
                                    
                                    sample.push(lowerKeys)
                               })
                               //konsole.log("samname",sample);


                        
                        const values = ['First Name', 'Phone', 'Email', 'Attendees']
                        const lower = values.map(element => {
                            return element.toLowerCase();
                          });
                        const lower1 = array.map(element => {
                            return element.toLowerCase();
                          });

                          let difference = lower.filter(x => !lower1.includes(x));
                       konsole.log("newdata11",difference)

                       konsole.log("newdata2",lower)
                        const multipleExist = lower.every(value => {
                            //konsole.log("newSan",lower1.includes(value))
                            return lower1.includes(value);
                        });
                         konsole.log("ddd",multipleExist)
                          if(multipleExist === true){
                            props.setFileList(sample)
                            props.setbutton(false)
                            setFileName(newFile.name)
                            
                            
                          }
                          else{
                            props.setbutton(true)
                            setFileName("")
                            let message1 = `Please upload a valid ${difference.map((ele)=> ele)}`
                            setWarning(message1)
                            setColor1("#ffcc00")
                            setShowModal(true)
                           
                          }
                        




                    }
                }
                reader.readAsArrayBuffer(file);
            }


        }

    }

    return (
        <>
        <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal}/>

            <div
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label">
                    <img src="fileUpload.svg" alt="" />
                    <p style={{ color: "black" }}>{fileName === "" ? "Drag & Drop your files here" : fileName}</p>
                </div>
                <input type="file" value="" onChange={onFileDrop} />
            </div>


            {/* <div className="drop-file-preview">
                <p className="drop-file-preview__title">
                    Ready to upload
                </p>
                {
                    fileList.map((item, index) => (
                        <div key={index} className="drop-file-preview__item">
                            <img src="Ellipse.png" alt="" />
                            <div className="drop-file-preview__item__info">
                                <p>{item.name}</p>
                                <p>{item.size}B</p>
                            </div>
                            <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                        </div>
                    ))
                }
            </div> */}


        </>
    )
}

export default FileUpload