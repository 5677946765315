import './Footer.css'

const Footer = () => {
    return (

        <div className='footerHeight bgclr1 ' style={{bottom:"0"}}>
            <div className='container-fluid bottom-0  position-static '>
                <div className='row'>
                    <div className='col main-footer-one '>©Copyright</div>
                    <div className='col main-footer-two'>
                        <h6 className='me-5' >Powered By</h6>

                        <img src='../images/FooterAOlogo.png' alt='footer'></img>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;