import moment from 'moment';
import React, { useContext, useEffect, useState, useRef } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { UserContext } from '../App';
import commonLib from './control/commonLib';
import { AOLOGINBASEURL } from './network/url';
import occurrenceId from './control/occurrence';
import LayoutComponent from './LayoutComponent';
import konsole from './network/konsole';
import Services from './network/Services';
import Notification from './Notification';
import '../components/PublishBoth.css'
// import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { isNotValidNullUndefinedfile } from './control/Constant';




export default function AfterPaymentScreen(props) {
  const [showModal, setShowModal] = useState(false)
  const { setLoader } = useContext(UserContext);
  const history = useHistory();
  const [plannerBooking, setPlannerBooking] = useState([]);
  const [noSlots, setNoSlots] = useState(false);
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const seminarObj = commonLib.getObjFromStorage("seminarObj");
  const uniquelink = `${AOLOGINBASEURL}account/Signin?subtenantId=${seminarObj.subtenantId}`
  const subtenatName = commonLib.getObjFromStorage("subtenatName");
  const plannerDataRef = useRef();
  const templeteId = useRef(false);
  const [warning, setWarning] = useState()
  const [Color1, setColor1] = useState("")
  const [hoveredDate, setHoveredDate] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const GetResponse = commonLib.getObjFromStorage('GetResponse');
  const [palnnerState, setPalnnerState] = useState()
  const [planningId, setPlanningId] = useState('')
  const selectedUser  = (isNotValidNullUndefinedfile(props.location.state)) ? props.location.state : GetResponse;


 
  const filterData = (len, data) => {
    for (let i = 0; i <= len - 1; i++) {
      if (data[i]?.plannerTime && data[i]?.plannerDate === data[i + 1]?.plannerTime && data[i + 1]?.plannerDate) {
     
      }
      else {
  
      }

    }



  }

  if (plannerBooking.length < 18) {
    let len = plannerBooking.length;
    filterData(len, plannerBooking);
  }
  else {
    let len = 18;
    filterData(len, plannerBooking)
  }

  useEffect(() => {
    window.addEventListener('popstate', (e) => {
      window.history.go(1);

    });
    plannerCalendr();
  
     Services.getPlannerPlan().then((resposne)=>{
      if(resposne){
        setPalnnerState(resposne?.data?.data)
       }
     })

  }, [])


  const numberFormate = (number) => {
    const lastTenDigitArray = array => array.slice(-10);
      if(number != null && number !== undefined && number != ""){
        return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
      }else{
       
        return number
      }

      }

  const plannerCalendr = () => {
    setLoader(true);
    let promises = Services.getPlannerCalender(true, 1, "UPCOMING", seminarObj.subtenantId);
    
    promises
      .then((res) => {
        setLoader(false);

        konsole.log("res", res)
        let slots = res?.data?.data
        konsole.log("Succeed", slots)
        if (slots === undefined || slots === null || slots === "" || slots.length === 0) {
          setNoSlots(true)

        }
        else {
          setPlannerBooking(slots)
        }

        // setPlannerBooking("")

      })
      .catch((error) => {
        konsole.log("error1", error)
      })
      .finally(error => {
        konsole.log("finish");
        setLoader(false);
      })
  }

  const selectedTimeSlotObj = (index) => {
        if(selectedTimeSlotIndex == index){
          setSelectedTimeSlotIndex(null);
          setSelectedTimeSlot(null)
          commonLib.setDatatoSession("timeSlot", JSON.stringify(""))
        }else{
          setSelectedTimeSlotIndex(index);
          const selectedTimeSlot = plannerBooking[index];
          setSelectedTimeSlot(selectedTimeSlot);
          const timeSlot = templeteId.current === true ? "" : {
            time: selectedTimeSlot.plannerTime,
            date: selectedTimeSlot.plannerDate,
            plannerCalId : selectedTimeSlot.plannerCalId
          }
          commonLib.setDatatoSession("timeSlot", JSON.stringify(timeSlot))
        }   
    
   

  }

  const postBookSumbit = () => {

    if (selectedTimeSlotIndex === null) {
      setWarning("Please Choose Booking Slot.")
      setColor1("#ffcc00")
      setShowModal(true)
      return;
    }else if(!isNotValidNullUndefinedfile(planningId)){
      setWarning("Please select planning option.")
      setColor1("#ffcc00")
      setShowModal(true)
      return;
    }
    else {
      commonLib.setDatatoSession('isNotSlots',JSON.stringify(false))
      history.push({
        pathname: '/venuePayment',
        state: selectedUser
      })
    
    }


  }
  const GetEmailTemplateUsers = () => {
    commonLib.setDatatoSession('isNotSlots',JSON.stringify(true))
     if(!isNotValidNullUndefinedfile(planningId)){
      setWarning("Please select planning option.")
      setColor1("#ffcc00")
      setShowModal(true)
      return;
    }else{
      history.push({
        pathname: '/venuePayment',
        state: selectedUser
      })
    }
   

    

  }
 
  
let dates = plannerBooking.map((ele) => {
return new Date(ele?.plannerDate)
})
  const isDateDisabled = (date) => {

    const dateString = date.toISOString().split('T')[0];
    return !dates.some((disabledDate) => {
      const disabledDateString = disabledDate.toISOString().split('T')[0];
      return disabledDateString === dateString;
    });
  };
  const newTime = (time, duration) => {
    var timeArray = time.split(' ');
    var timeString = timeArray[0];
    var period = timeArray[1];
    var [hours, minutes] = timeString.split(':').map(Number);
    minutes += Number(duration); // Convert duration to a number
    hours += Math.floor(minutes / 60); // Add excess minutes to hours
    minutes = minutes % 60; // Get remaining minutes after converting to hours
  
    if (hours >= 12) {
      period = period === 'AM' ? 'PM' : 'AM';
      if (hours > 12) {
        hours -= 12;
      }
    }
    if (hours === 0) {
      hours = 12;
    }
  
    var updatedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${period}`;
    const formattedTime = moment(updatedTime, "HH:mm A").format("h:mm A");
    return formattedTime;
  }

 const stateUpdate =()=>{
    setHoveredDate(null)
  }
  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const isHovered = hoveredDate && date.getTime() === hoveredDate.getTime();
      const classNames = `calendar-tile ${isHovered ? 'hovered' : ''}`;

      return (
        <div
          key={date.getTime()}
          className={classNames}
          onMouseEnter={(event) => handleDateHover(date, { top: event.clientY, left: event.clientX })}
          onMouseLeave={() => stateUpdate()}
        >
          {date.getDate()}
          {isHovered && renderDropdown(date)}
          {/* {renderDropdown(date)} */}
        </div>
      );
    }
  };
 
  const handleDateHover = (date, mouseCoordinates) => {
    if (!isDateDisabled(date)) {
      setHoveredDate(date);
      // Calculate dropdown position relative to the calendar container
      const calendarContainer = document.querySelector('.react-calendar');
      const { top: containerTop, left: containerLeft } = calendarContainer.getBoundingClientRect();
      const { top: mouseTop, left: mouseLeft } = mouseCoordinates;

      const dropdownTop = mouseTop - containerTop + calendarContainer.scrollTop + 30; // Adjust the value (30) to set the desired distance from the hovered date

      setDropdownPosition({
        top: dropdownTop,
        left: mouseLeft 
      });

    }
  };
 const handelSelect=(ele,index)=>{
  setSelectedTimeSlotIndex(ele?.plannerCalId); 
  setSelectedTimeSlot(ele);
 }
  const renderDropdown = (date) => {
    let newjson = plannerBooking.filter((ele) => {

      return new Date(ele?.plannerDate).getTime() == date.getTime()

    })
    return (
      <div className="newDropdown" style={{top: dropdownPosition.top, left: dropdownPosition.left}}>
        <ul className='p-2 ulData vertical-scroll'style={{maxHeight:"150px",overflowX:"auto"}}>
          {newjson.map((ele,index) => {
            return (
              <>
                <li className={`gray-border rounded mb-1  ${(selectedTimeSlotIndex === ele?.plannerCalId) ? 'seletectedIndexBackground px-0 p-2 vertical-scroll' : 'border border-dark p-2 vertical-scroll'}p-2 w-100 text-center  cursor-pointer`}
                 onClick={()=>handelSelect(ele,index)}>{ele?.plannerTime} To {newTime(ele?.plannerTime,ele?.timeDuration)}</li>
              </>
            )
          })}

        </ul>
      </div>

    );
  };

  const handelClick=(e)=>{
    let planningData = {
      "planningId" : e.target?.id,
      "planningName" :e.target?.name
    }
    setPlanningId(e.target?.id)
      commonLib.setDatatoSession("planningData", JSON.stringify(planningData));      
  }
    
  const showPlanningDetails = () => {
    // Inline styles defined in a JavaScript object
    const containerStyle = {
      textAlign: 'justify', 
    };
  
    const headingStyle = {
      textAlign: 'justify'
    };
  
    return (
      <div className='container border mt-4 mb-2 p-4 estimatePlaning'>
        <h4 style={headingStyle}>
          Please estimate which planning option fits your needs more closely ?*
        </h4>
        {isNotValidNullUndefinedfile(palnnerState) && palnnerState.map((ele) => (
          <React.Fragment key={ele.planningId}>
            <div className={`d-flex ${ele.planningId == planningId ? 'selected-border cursor-pointer' : 'notSelected cursor-pointer'}`} onClick={(e) => setPlanningId(ele.planningId)}>
              <input
                className='me-2 mb-auto mt-2'
                id={ele.planningId}
                checked={ele.planningId == planningId}
                // disabled={!isNotValidNullUndefinedfile(selectedTimeSlot)}
                type='checkbox'
                name={ele?.planningName}
                onChange={(e) => handelClick(e)}
              />
              {konsole.log(ele.planningId ,"dataChecked")}
              <div style={containerStyle}>
                <h5>{ele.planningName}</h5>
                <p >{ele.planningDesc}</p>
              </div>
            </div>
        </React.Fragment>
        ))}
          {isNotValidNullUndefinedfile(planningId) && <h6 style={headingStyle}>Note: You can discuss this selection during the initial Consultation.</h6>
}  
      </div>
    );
  };
  
  


  return (
    <LayoutComponent>
      <div>
        <Notification showModal={showModal} message={warning} color={Color1} setShowModal={setShowModal} />
        {/* {(paymentSuccesStatus == "SUCCESS" || props.location.state?.paymentType === "Cash") ? */}

        <div className='contenArea container-fluid   bgclr' >
          <div className='row'>
            <div className='col-sm-6 '>
              <h5 className=''>Appointment Booking : Book an Appointment</h5>
              <p className='fs-5'><b>Topic</b>: {seminarObj?.seminarTopic}</p>
            </div>
          </div>
          <div className='container after-payment-container bg-white text-center vertical-scroll' style={{ maxHeight: '100%', overflowY: 'auto' }}>
            <h1 className='congratulationNew fw-bold fs-1 pb-0 mb-0'>Choose date and time slot to book an appointment</h1><br />
            {noSlots == true
              ? (
                <>
                  <h5 className='mt-1 fw-800 fs-4'>Sorry for the inconvenience.</h5>
                  <h5 className='mt-1 fw-800 fs-4'>There are no Appointment  slots available currently, please click on "No preferred time slots available"
                    so that one of our team members will contact you as soon as possible. </h5><br /><br /><br />
                       {showPlanningDetails()}
                    </>
              )
               : (
                <>
                  <div className='row ms-2 ms-sm-0 gap-3 align-items-center justify-content-center w-100  ' >

                    {
                      plannerBooking?.map((item, index) => {
                        const { formattedDate, dayAndYear } = commonLib?.getFormattedDateNew(item.plannerDate);
                        return (
                          <>

                            <div key={index} onClick={() => selectedTimeSlotObj(index)}

                              className={`gray-border rounded ${(selectedTimeSlotIndex === index) ? ' seletectedIndexBackground px-0 p-2 vertical-scroll' : 'border border-dark p-2 vertical-scroll'}p-2 w-100 text-center  cursor-pointer`} style={{ maxWidth: '220px' }}
                            >
                              {formattedDate}<br />{dayAndYear}<br /> {item.plannerTime}
                            </div>
                          </>
                        )
                      })
                    }

                    {/* <div className="my-calendar mt-0 position-relative ">
                        <Calendar className="calendar" tileDisabled={({ date }) => isDateDisabled(date)} tileContent={tileContent} />

                      </div> */}

                  </div>
                  {/* planner Plannin code */}
                   {showPlanningDetails()}
                   {/* ////////////////////// */}

                   
                  <div className='btm d-flex justify-content-center mt-3 pb-1'>

                    {selectedTimeSlot != null && selectedTimeSlot != undefined && selectedTimeSlot != "" ? <div className='text-center TimeSlot p-2 me-2'>
                      {selectedTimeSlot.plannerTime} To {newTime(selectedTimeSlot?.plannerTime, selectedTimeSlot?.timeDuration)}
                    </div> : ""}
                    <button type='text' onClick={postBookSumbit} className='theme-btn rounded text-center p-2 '>
                      BOOK NOW
                    </button>

                  </div>
                </>
              )


            }

            <div className='btm text-center mt-1 pb-2'>
              <h5>No preferred time slots available? Please &nbsp;
                <a className='fs-5 text-decoration-underline' style={{ cursor: "pointer" }} onClick={GetEmailTemplateUsers} >
                  click here
                </a></h5>
            </div>
          </div>
        </div>



      </div>
    </LayoutComponent>
  )
}
