import { useState } from "react";
import { useContext, useEffect } from "react";
import { BrowserRouter, Link, NavLink, Route, Switch } from "react-router-dom";
import { UserContext } from "../../App";
import commonLib from "../control/commonLib";
import '../Headers/header.css';
import konsole from "../network/konsole";
import { Button, Modal } from 'react-bootstrap';
import { AoPartnerBaseUrlMobile,AOLOGINBASEURL } from '../network/url';
import AccountRoleChange from "./AccountRoleChange";


const Header = (props) => {

  let { subtenantLogo } = useContext(UserContext);
  const [loggeduser, setloggeduser] = useState('')
  const [showModal, setshowModal] = useState(false)
  // konsole.log("sample",loggeduser)
  const subtenantId = commonLib.getSessionStoarge('subtenantId')
  // konsole.log("sample",subtenantId)
  const handleLogout = (e) => {
    setshowModal(true);
    }
 
  const handleModalClose = () => {
    setshowModal(false);
    };
  const handleModalsubmit=()=>{
    setshowModal(false);
    const stateObj = commonLib.getObjFromStorage('stateObj');
    let params = `appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}&success=${true}`;

    // const params = `Account/Signout?appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggedInUserId}`;
    konsole.log("params", params);
    sessionStorage.clear()
    window.location.replace( `${AOLOGINBASEURL}Account/Signout?` + params);
  }



  useEffect(() => {
    const loggeduser = JSON.parse(commonLib.getSessionStoarge('userLoggedInDetail'))?.memberName
    konsole.log("loggeduser", loggeduser)
    setloggeduser(loggeduser)

  })

  // useEffect(()=>{
  //   let loggeduser = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
  //   konsole.log("loggeduser",loggeduser)
  //   setloggeduser(loggeduser)
  // })


  function Mobileview() {
    const stateObj = commonLib.getObjFromStorage('stateObj');
    konsole.log("stateObj", stateObj)
    // const GetResponse = JSON.parse(sessionStorage.getItem('GetResponse'));
    const userId = stateObj?.userId
    konsole.log("aab", userId)
    let params = `appState=${stateObj.appState}&userId=${userId}&loggedUserId=${userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}`;
    window.location.replace(`${AoPartnerBaseUrlMobile}?token=` + window.btoa(params));
    konsole.log("params", stateObj.appState, stateObj, params)

  }
  return (
    <>

      <nav className="head navbar navbar-expand-lg bg-white sticky-top bg-white header-Height pt-1 pb-1" >
        <div className="container-fluid  header-Height-container">
          <div className=" MainHeaderLogo m-0 p-0 d-flex justify-content-between">
            {
              subtenantLogo !== '' ? <img src={subtenantLogo} className='subtenantLogo ' alt="Login user image" /> : <h2><b>SUBTENANT LOGO</b></h2>
            }
          </div>
          {/* <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
          <span className="close-icon  ">✖</span>
          <span className="navbar-toggler-icon "></span>
           
          </button> */}

          <button className="navbar-toggler Navbarnav collapsed d-flex d-lg-none flex-column justify-content-around border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            {/* <span className="close-icon  ">✖</span> */}
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
          <div className="collapse navbar-collapse w-25 text-end header-Height bg-white" id="navbarSupportedContent">
            <ul className="navbar-nav plannertext ms-auto bg-white pe-2"  >
              {
                props.pathName !== "Dashboard" ?
                  <>
                    <li className="nav-item">
                      <NavLink className="nav-link   pt-4" aria-current="page" to="/Dashboard">Home</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pt-4 " aria-current="page" to="/Counter">Check-In</NavLink>
                    </li>


                    <li className="nav-item">
                      {subtenantId == 745 ? "" :
                        <NavLink className="nav-link pt-4 " aria-current="page" to="/PublishBoth">Publish Link</NavLink>}
                    </li>
                    <li className="nav-item">
                      {subtenantId == 745 ? "" :
                        <NavLink className="nav-link pt-4 me-2" to="/CounterPlanner">Appointment Booking</NavLink>}
                    </li>

                    <li className="nav-item">
                      {subtenantId == 745 ? "" :
                        <NavLink className="nav-link pt-4 me-2" to="/classDashBord">Workshop</NavLink>
                      }
                    </li>


                    <li className="nav-item dropdown">
                      {subtenantId == 745 ? "" :
                        <NavLink className="nav-link pt-4" to="/ConfirmationList" role="button" >
                          Confirmation List
                        </NavLink>}
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link pt-4 me-2 " to="/FollowUpList" tabIndex="-1" aria-disabled="true">Follow-up-List
                      </NavLink>
                    </li>
                    <li className="Vertical d-none d-lg-block">
                    </li>
                  </>
                  :
                  <>
                    <li className="nav-item dropdown">
                      {subtenantId == 745 ? "" :
                        <NavLink className="nav-link pt-4 " to="/Publishseminar" role="button" >
                          Publish Seminar
                        </NavLink>}
                    </li>
                    <li className="nav-item dropdown nav-link">
                      <div className="nav-link  pt-3 cursor-pointer" onClick={() => Mobileview()}>
                        Mobile View
                      </div>
                    </li>
                  </>
              }
              <li className="nav-item">
                <a className="nav-link pt-4" href="#" tabIndex="-1" aria-disabled="true">Hi, {loggeduser}</a>
              </li>
              <li className="nav-item">
              <a className="btn nav-link pt-4" >
              <AccountRoleChange />  
              </a>
              </li>
              
              <li className="nav-item logoutCircle">
                <a className="nav-link  pt-4 d-none d-lg-block" onClick={handleLogout} tabIndex="-1" aria-disabled="true">
                  <img src='/icons/logoutcircle.svg' className="cursor-pointer"></img>
                </a>
                <a className="nav-link   pt-4 d-lg-none" onClick={handleLogout} tabIndex="-1" aria-disabled="true">Log Out</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
       <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header 
        closeButton>
          <span className="">Are you sure you want to logout ?</span>
        
          {/* <Modal.Title>Are you sure you want to logout?</Modal.Title> */}
        </Modal.Header>
        {/* <Modal.Body>{"Are you sure you want to logout?"}</Modal.Body> */}
        <Modal.Footer className="modal_s">
          <Button variant="secondary" onClick={handleModalClose} style = {{ backgroundColor: 'white', color: '#720c20' }} >
            No
          </Button>
          <Button variant="primary" onClick={handleModalsubmit} style={{ backgroundColor: '#720c20', color: 'white',border:"none" }}>Yes</Button>
        </Modal.Footer>
      </Modal>



    </>
  );
}

export default Header;